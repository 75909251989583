var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "schedule-players" },
      _vm._l(_vm.freePlayerList, function (p) {
        return _c("player-button", {
          key: p.id,
          attrs: { player: p, layout: _vm.layout, onClick: _vm.band },
        })
      }),
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "schedule-players" },
      _vm._l(_vm.teamList, function (t, idx) {
        return _c(
          "div",
          { key: t.team_number, staticClass: "team" },
          [
            _c("div", [_vm._v("隊伍 " + _vm._s(idx + 1))]),
            _vm._v(" "),
            _vm._l(t.players, function (p) {
              return _c("player-button", {
                key: p.id,
                attrs: { player: p, layout: _vm.layout },
              })
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-danger bg-red",
                on: {
                  click: function ($event) {
                    return _vm.disband(t.team_number)
                  },
                },
              },
              [_vm._v("解散")]
            ),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }