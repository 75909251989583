var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notifications", class: { active: _vm.active } },
    [
      _c("div", { staticClass: "title", class: { highlight: _vm.highlight } }, [
        _vm._v("\n    通知\n    "),
        _c("button", {
          staticClass: "close-button",
          on: { click: _vm.toggle },
        }),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.list, function ({ id, ...n }) {
          return _c("Li", { key: id, attrs: { notification: n } })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }