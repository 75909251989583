<template>
  <div>
    <br>
    <div class="schedule-players">
      <player-button
        v-for="p in freePlayerList"
        :key="p.id"
        :player="p"
        :layout="layout"
        :onClick="band"
      />
    </div>
    <br>
    <div class="schedule-players">
      <div v-for="(t, idx) in teamList" :key="t.team_number" class="team">
        <div>隊伍 {{ idx + 1 }}</div>
        <player-button v-for="p in t.players" :key="p.id" :player="p" :layout="layout" />
        <button class="btn btn-danger bg-red" @click="disband(t.team_number)">解散</button>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerButton from '../Player/PlayerButton';
import axios from 'axios';

const meta = document.querySelector('meta[name=csrf-token]');
const token = meta && meta.content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

const orderBy = {
  score: (a, b) => b.point - a.point,
  time: (a, b) => new Date(b.last_active_at || undefined) - new Date(a.last_active_at || undefined),
};

export default {
  props: ["id", "schedule_players"],
  components: { PlayerButton },

  data() {
    return {
      layouts: [
        { type: 'grid', icon: 'th-large' },
        { type: 'list', icon: 'list' },
      ],
      orders: [
        { type: 'time', icon: 'time' },
        { type: 'score', icon: 'object-align-bottom' },
      ],
      layout: 'list',
      order: 'score',
      players: this.schedule_players,
      nextTeamNumber: null
    };
  },

  methods: {
    band(player_id) {
      const { id, players, teamNumbers } = this;
      let clearTeamNumber = false;
      if (this.nextTeamNumber == null) {
        if (teamNumbers.length == 0) {
          this.nextTeamNumber = 1;
        }
        else {
          this.nextTeamNumber = Math.max(...teamNumbers) + 1;
        }
      } else {
        clearTeamNumber = true;
      }
      this.players = players.map((player) => {
        if (player.id == player_id) {
          player.team = this.nextTeamNumber;
        }
        return player;
      });

      if (clearTeamNumber == true) {
        const formData = new FormData();
        const band_players = this.players.filter( player => player.team == this.nextTeamNumber )

        formData.append('team', this.nextTeamNumber);
        formData.append('player1_id', band_players[0].id)
        formData.append('player2_id', band_players[1].id)
        axios.post(`/in/schedules/${id}/teams`, formData).then((res) => {
            this.$swal({ position: 'top-end', showConfirmButton: false, timer: 2000, toast: true, icon: "success", title: "組隊成功" })
          }).catch(function (error) {
            this.$swal({ position: 'top-end', showConfirmButton: false, timer: 2000, toast: true, icon: "error", text: "組隊失敗，請稍後再試" })
          });

        this.nextTeamNumber = null;
      }
    },
    disband(teamNumber) {
      const { id, players } = this;
      this.players = players.map((player) => {
        if (player.team == teamNumber) {
          player.team = null
        }
        return player;
      });

      const formData = new FormData();

      formData.append('team', teamNumber);
      axios.delete(`/in/schedules/${id}/teams/${teamNumber}`, formData).then((res) => {
            this.$swal({ position: 'top-end', showConfirmButton: false, timer: 2000, toast: true, icon: "success", title: "隊伍解散" })
          }).catch(function (error) {
            this.$swal({ position: 'top-end', showConfirmButton: false, timer: 2000, toast: true, icon: "error", text: "解散失敗，請稍後再試" })
          });
    },
  },

  computed: {
    freePlayerList() {
      const { players, order } = this;
      return (players || [])
        .filter(player => player.team == null)
        .sort(orderBy[order])
    },

    teamList() {
      const { players } = this;
      const team_numbers = this.teamNumbers;
      let teams = [];
      team_numbers.forEach((i) => {
        teams.push({team_number: i, players: players.filter(player => player.team == i)});
      });
      return teams;
    },

    teamNumbers() {
      const { players } = this;
      const numbers = (players || [])
        .map(player => player.team)
        .filter(team => team != null);
      return [...new Set(numbers)].sort();
    }
  },
};
</script>

<style lang="scss" scoped>
  .schedule-players {
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 700px) { grid-template-columns: repeat(3, 1fr); }
    @media (min-width: 1000px) { grid-template-columns: repeat(4, 1fr); }
  }

  .team {
    display: grid;
    grid-gap: 10px;
    padding: 10px 10px 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
</style>
