<template>
  <div class="record">
    <div class="record-head space-between" v-if="!pure">
      <div>
        <a :href="last_step_url" v-if="last_step_url">
          <span class="glyphicon glyphicon-backward text-blue"></span> 回到上一步
        </a>
      </div>
      <div></div>
      <div>
        <a :href="next_step_url" v-if="next_step_url">
          <span class="glyphicon glyphicon-play text-green"></span> 細部配對
        </a>
      </div>
    </div>

    <div class="record-card">
      <div class="record-team">
        <a
          v-for="item in mate_players"
          :key="item.id"
          v-bind:class="getPlayerClass(item.id, true)"
          :href="playerUrl(item)"
        >
          <img :src="item.avatar_url" />
          <div class="info full">{{ item.name }}</div>
        </a>
      </div>

      <div class="record-score">
        <div class="score">
          <div v-html="mate_wins_count"></div>
          <div class="point hidden-xs">( {{ matePoint }} )</div>
        </div>
        <div>-</div>
        <div class="score">
          <div v-html="mate_loses_count"></div>
          <div class="point hidden-xs">( {{ enemyPoint }} )</div>
        </div>
      </div>

      <div class="record-team">
        <a
          v-for="item in enemy_players"
          :key="item.id"
          v-bind:class="getPlayerClass(item.id, false)"
          :href="playerUrl(item)"
        >
          <img :src="item.avatar_url" />
          <div class="info full">{{ item.name }}</div>
        </a>
        <a
          v-for="n in (2 - enemy_players.length)"
          :key="n"
          v-bind:class="getPlayerClass(0, false)"
        >
          <img :src="default_avatar_url" />
          <div class="info full"></div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'pure',
    'mate_players',
    'enemy_players',
    'mate_wins_count',
    'mate_loses_count',
    'me_player_id',
    'last_step_url',
    'next_step_url',
    'default_avatar_url'
  ],
  mounted() {
  },

  computed: {
    matePoint() {
      return this.teamPoint(this.mate_players)
    },
    enemyPoint() {
      return this.enemy_players.length == 2
        ? this.teamPoint(this.enemy_players)
        : "-";
    }
  },

  methods: {
    playerUrl(player) {
      return `/players/${player.id}`;
    },
    teamPoint(players) {
      if (players.filter(p => p.is_formal == false).length > 0) {
        return "-";
      }
      return Math.floor(
        players.reduce((acc, item) => acc + item.point, 0) / players.length
      );
    },
    getPlayerClass(player_id, is_winner) {
      let classes = "player player-label right"
      if (this.me_player_id == player_id) {
        classes += " me";
      }
      if (is_winner) {
        classes += " bg-green";
      } else {
        classes += " bg-gray-light";
      }
      return classes;
    }
  },
};
</script>
