<template>
  <div class="scorer-container">
    <tabs-header v-if="!hasStart" :tabs="tabs" :active="tab" :set-active="setTab" />

    <div v-if="!hasStart && round === 1" class="settings-container text-white">
      <div class="setting-button" @click="toggleSetting">
        <span class="glyphicon glyphicon-cog"></span>
      </div>

      <div v-show="settingsActive" class="setting-modal">
        <h4>回合數</h4>
        <div class="input-group input-group--list">
          <label for="rounds-one">
            <input id="rounds-one" type="radio" value="1" v-model="rounds"/>
            <span>1</span>
          </label>
          <label for="rounds-three">
            <input id="rounds-three" type="radio" value="3" v-model="rounds"/>
            <span>3</span>
          </label>
          <label for="rounds-five">
            <input id="rounds-five" type="radio" value="5" v-model="rounds"/>
            <span>5</span>
          </label>
        </div>

        <hr />

        <h4>獲勝分數</h4>
        <div class="input-group input-group--list">
          <label for="match-score-eleven">
            <input id="match-score-eleven" type="radio" value="11" v-model="matchScore"/>
            <span> 搶 11</span>
          </label>
          <label for="match-score-twentyone">
            <input id="match-score-twentyone" type="radio" value="21" v-model="matchScore"/>
            <span> 21 （ deuce ）</span>
          </label>
          <label for="match-score-twentyfive">
            <input id="match-score-twentyfive" type="radio" value="25" v-model="matchScore"/>
            <span> 搶 25</span>
          </label>
          <label for="match-score-thirty">
            <input id="match-score-thirty" type="radio" value="30" v-model="matchScore"/>
            <span> 搶 30</span>
          </label>
          <label for="match-score-thirtyone">
            <input id="match-score-thirtyone" type="radio" value="31" v-model="matchScore"/>
            <span>搶 31</span>
          </label>
        </div>

        <hr />

        <h4>起始分數</h4>
        <div class="input-group">
          <span class="input-group-addon">藍隊：</span>
          <input type="number" class="form-control" v-model="blueStartScore"/>
        </div>
        <div class="input-group">
          <span class="input-group-addon">紅隊：</span>
          <input type="number" class="form-control" v-model="redStartScore"/>
        </div>
      </div>
    </div>

    <div :class="{ 'disabled-part': settingsActive }">
      <scorer-tool
        v-show="tab === 'tool'"
        :hasStart="hasStart"
        :race="race"
        :team="team"
        :rounds="rounds"
        :match-score="matchScore"
        :blue-start-score="blueStartScore"
        :red-start-score="redStartScore"
        :onClick="onOpen"
        :onChange="onChange"
        :onSubmit="onSubmit"
        :setHasStart="setHasStart"
      />

      <scorer-direct
        v-show="tab === 'direct'"
        :race="race"
        :team="team"
        :rounds="rounds"
        :match-score="+matchScore"
        :blue-start-score="+blueStartScore"
        :red-start-score="+redStartScore"
        :redScore="getValue(redScore, redStartScore, matchScore)"
        :blueScore="getValue(blueScore, blueStartScore, matchScore)"
        :onClick="onOpen"
        :onChange="onChange"
        :onSubmit="onSubmit"
      />
    </div>

    <p v-show="!hasStart" class="buttons">
      <confirm-button
        custome-class="btn-danger bg-red btn-lg"
        icon="remove"
        btn-text="取消比賽"
        title="確定取消比賽嗎？"
        type="warning"
        confirm-text="是，刪除這場比賽"
        :confirm-url="`/races/${race.id}/cancel`"
      />
      <confirm-button
        custome-class="btn-warning bg-orange btn-lg"
        icon="forward"
        btn-text="略過記錄"
        title="確定略過記錄不計比分嗎？"
        type="warning"
        confirm-text="是，略過這場記錄"
        :confirm-url="`/races/${race.id}/bypass`"
      />
    </p>

    <player-selector
      v-if="group && round == 1"
      :fixed="true"
      :active="!!selecting"
      :players="players"
      :selected="selectedIds"
      :onClick="onClick"
      :onClose="onClose"
    />

    <form
      class="hide"
      :action="`/races/${race.id}/finish`"
      method="post"
      ref="form"
    >
      <input name="authenticity_token" :value="token" />

      <input v-if="team.red1" name="record[red1_id]" :value="team.red1.id" />
      <input v-if="team.red2" name="record[red2_id]" :value="team.red2.id" />

      <input v-if="!team.blue2 && team.blue1" name="record[blue1_id]" :value="team.blue1.id" />
      <input v-else-if="team.blue1" name="record[blue2_id]" :value="team.blue1.id" />

      <input v-if="team.blue2" name="record[blue1_id]" :value="team.blue2.id" />

      <input name="record[red_score]" :value="redScore || 0" />
      <input name="record[blue_score]" :value="blueScore || 0" />

      <template v-if="tab === 'tool'">
        <input v-if="detail" name="record[detail]" :value="detail" />
      </template>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import TabsHeader from '../Tabs/TabsHeader';
import ScorerTool from './ScorerTool';
import ScorerDirect from './ScorerDirect';
import PlayerSelector from '../Player/PlayerSelector';
import ConfirmButton from '../Button/ConfirmButton';

const defaultTabs = [
  { type: 'tool', text: '計分器' },
  { type: 'direct', text: '輸入結果' }
];

export default {
  props: ['group', 'players', 'race', 'round', 'token'],

  components: {
    TabsHeader,
    ScorerTool,
    ScorerDirect,
    PlayerSelector,
    ConfirmButton,
  },

  data() {
    const lastTab = localStorage.getItem('scorer-tab');

    return {
      selecting: undefined,

      tabs: defaultTabs,
      tab: lastTab || 'direct',

      matchScore: this.race.match_score,
      blueStartScore: 0,
      redStartScore: 0,
      rounds: this.race.rounds,

      red1: undefined,
      red2: undefined,
      blue1: undefined,
      blue2: undefined,

      detail: '',

      redScore: this.race.match_score,
      blueScore: this.race.match_score,

      hasStart: false,

      settingsActive: false,
    };
  },

  computed: {
    team() {
      const { red1, red2, blue1, blue2, players = [], race = {} } = this;
      const ids = {
        red1: red1 !== undefined ? red1 : race.red1_id,
        red2: red2 !== undefined ? red2 : race.red2_id,
        blue1: blue1 !== undefined ? blue1 : race.blue1_id,
        blue2: blue2 !== undefined ? blue2 : race.blue2_id,
      };

      const team = ['red1', 'red2', 'blue1', 'blue2'].reduce(
        (t, key) => ({
          ...t,
          [key]: players.filter((p) => p.id === ids[key])[0],
        }),
        {}
      );

      return team;
    },
    selectedIds() {
      const { red1, red2, blue1, blue2 } = this.team;
      return [red1, red2, blue1, blue2].filter((s) => s).map(({ id }) => id);
    },
    canStart() {
      const { red1, red2, blue1, blue2 } = this.team;
      const red = [red1, red2].filter(p => p).map(({ id }) => id);
      const blue = [blue1, blue2].filter(p => p).map(({ id }) => id);

      const sameCount = red.length === blue.length;
      const hasRepeat = _.uniq([...red, ...blue]).length < red.length + blue.length;

      return sameCount && !hasRepeat;
    },
  },

  methods: {
    getValue(value, start, match) {
      const max = +match === 21 ? 30 : +match;
      const current = Math.max(start, value);
      return Math.min(current, max);
    },

    toggleSetting() {
      this.settingsActive = !this.settingsActive;
    },

    setHasStart(hasStart) {
      this.hasStart = hasStart;
    },

    setTab(tab) {
      this.tab = tab;
      localStorage.setItem('scorer-tab', tab);
    },

    onOpen(nextSelecting) {
      if (this.team[nextSelecting]) {
        this[nextSelecting] = null;
      } else {
        this.selecting = nextSelecting;
      }
    },

    onClick(id) {
      const { selecting, onClose } = this;
      onClose();
      this[selecting] = id;
    },

    onClose() {
      this.selecting = undefined;
    },

    onChange(name, value) {
      this[name] = value;
    },

    onSubmit() {
      this.$refs.form.submit();
    },
  },

  watch: {
    canStart() {
      if (!this.canStart) return;

      const { team, race } = this;
      const { red1, red2, blue1, blue2 } = team;
      const red = [red1, red2].filter(p => p).map(({ id }) => id);
      const blue = [blue1, blue2].filter(p => p).map(({ id }) => id);

      axios.put(`/in/races/${race.id}/update_players`, { red, blue });
    },
  },
};
</script>

<style scoped lang="scss">
.scorer-container {
  position: relative;
}

.disabled-part {
  opacity: 0.9;
  pointer-events: none;
  filter: grayscale(0.9);
}

.settings-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  margin: 2px -5px 0 0;
  user-select: none;
}

.setting-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
}

.setting-modal {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 20;
  width: 240px;
  padding: 15px 20px;
  background: #242424;
  border-radius: 5px;
  box-shadow: 0 0 3px 2px #555;
}

.input-group--list {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px 0;

  label {
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    height: 40px;
    margin: 5px;
    cursor: pointer;
  }

  span {
    flex-grow: 1;
    line-height: 40px;
    text-align: center;
    background: #555;
    border-radius: 5px;
    transition: background 0.3s;
  }

  input[type='text'] {
    color: #000;
  }

  input[type='radio'] {
    display: none;

    &:checked + span {
      background: #1b813e;
    }
  }
}

.input-group + .input-group {
  margin: 5px 0 0;
}
</style>
