var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "field-box left even" },
    [
      _c("div", { staticClass: "background", style: _vm.servingStyle }),
      _vm._v(" "),
      _c("img", { attrs: { src: "/assets/images/field.svg", alt: "" } }),
      _vm._v(" "),
      _vm._l(_vm.players, function ({ team, order, player, style }) {
        return _c("field-player-button", {
          key: `${team}${order}`,
          attrs: {
            team: team,
            order: order,
            player: player,
            started: _vm.started,
            customStyle: style,
            onClick: _vm.onClick,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }