var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      staticClass: "form-control",
      domProps: { value: _vm.value },
      on: { change: _vm.onSelectChange },
    },
    _vm._l(_vm.formattedOptions, function ({ value, label, disabled }) {
      return _c(
        "option",
        {
          key: value,
          attrs: { disabled: disabled },
          domProps: { value: value },
        },
        [_vm._v("\n    " + _vm._s(label) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }