<template>
  <div>
    <br>
    <div class="schedule-players">
      <player-button
        v-for="p in freePlayerList"
        :key="p.id"
        :player="p"
        :layout="layout"
        :onClick="band"
      />
    </div>
    <br>
    <div class="schedule-players">
      <div v-for="(t, idx) in apartList" :key="t.apart_number" class="team">
        <div>組合 {{ idx + 1 }}</div>
        <player-button v-for="p in t.players" :key="p.id" :player="p" :layout="layout" />
        <button class="btn btn-danger bg-red" @click="disband(t.apart_number)">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerButton from '../Player/PlayerButton';
import axios from 'axios';

const meta = document.querySelector('meta[name=csrf-token]');
const token = meta && meta.content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

const orderBy = {
  score: (a, b) => b.point - a.point,
  time: (a, b) => new Date(b.last_active_at || undefined) - new Date(a.last_active_at || undefined),
};

export default {
  props: ["id", "schedule_players"],
  components: { PlayerButton },

  data() {
    return {
      layouts: [
        { type: 'grid', icon: 'th-large' },
        { type: 'list', icon: 'list' },
      ],
      orders: [
        { type: 'time', icon: 'time' },
        { type: 'score', icon: 'object-align-bottom' },
      ],
      layout: 'list',
      order: 'score',
      players: this.schedule_players,
      nextApartNumber: null
    };
  },

  methods: {
    band(player_id) {
      const { id, players, apartNumbers } = this;
      let clearApartNumber = false;
      if (this.nextApartNumber == null) {
        if (apartNumbers.length == 0) {
          this.nextApartNumber = 1;
        }
        else {
          this.nextApartNumber = Math.max(...apartNumbers) + 1;
        }
      } else {
        clearApartNumber = true;
      }
      this.players = players.map((player) => {
        if (player.id == player_id) {
          player.apart = this.nextApartNumber;
        }
        return player;
      });

      if (clearApartNumber == true) {
        const formData = new FormData();
        const band_players = this.players.filter( player => player.apart == this.nextApartNumber )

        formData.append('apart', this.nextApartNumber);
        formData.append('player1_id', band_players[0].id)
        formData.append('player2_id', band_players[1].id)
        axios.post(`/in/schedules/${id}/aparts`, formData).then((res) => {
            this.$swal({ position: 'top-end', showConfirmButton: false, timer: 2000, toast: true, icon: "success", title: "設定互斥成功" })
          }).catch(function (error) {
            this.$swal({ position: 'top-end', showConfirmButton: false, timer: 2000, toast: true, icon: "error", text: "設定互斥失敗，請稍後再試" })
          });

        this.nextApartNumber = null;
      }
    },
    disband(apartNumber) {
      const { id, players } = this;
      this.players = players.map((player) => {
        if (player.apart == apartNumber) {
          player.apart = null
        }
        return player;
      });

      const formData = new FormData();

      formData.append('apart', apartNumber);
      axios.delete(`/in/schedules/${id}/aparts/${apartNumber}`, formData).then((res) => {
            this.$swal({ position: 'top-end', showConfirmButton: false, timer: 2000, toast: true, icon: "success", title: "互斥組合取消" })
          }).catch(function (error) {
            this.$swal({ position: 'top-end', showConfirmButton: false, timer: 2000, toast: true, icon: "error", text: "互斥組合取消失敗，請稍後再試" })
          });
    },
  },

  computed: {
    freePlayerList() {
      const { players, order } = this;
      return (players || [])
        .filter(player => player.apart == null)
        .sort(orderBy[order])
    },

    apartList() {
      const { players } = this;
      const apart_numbers = this.apartNumbers;
      let aparts = [];
      apart_numbers.forEach((i) => {
        aparts.push({apart_number: i, players: players.filter(player => player.apart == i)});
      });
      return aparts;
    },

    apartNumbers() {
      const { players } = this;
      const numbers = (players || [])
        .map(player => player.apart)
        .filter(apart => apart != null);
      return [...new Set(numbers)].sort();
    }
  },
};
</script>

<style lang="scss" scoped>
  .schedule-players {
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 700px) { grid-template-columns: repeat(3, 1fr); }
    @media (min-width: 1000px) { grid-template-columns: repeat(4, 1fr); }
  }

  .team {
    display: grid;
    grid-gap: 10px;
    padding: 10px 10px 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
</style>
