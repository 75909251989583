var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.level,
            expression: "level",
          },
        ],
        staticClass: "form-control",
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.level = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.onChange,
          ],
        },
      },
      [
        _c("option", { domProps: { value: 0 } }, [_vm._v("系統判定")]),
        _vm._v(" "),
        _c("option", { attrs: { disabled: "" } }, [
          _vm._v("------------------"),
        ]),
        _vm._v(" "),
        _vm._l(_vm.levels, function (l, i) {
          return _c("option", { key: i, domProps: { value: i + 1 } }, [
            _vm._v(_vm._s(l)),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.point,
          expression: "point",
        },
      ],
      staticClass: "form-control",
      class: { editable: false },
      attrs: { type: "number", name: "points[]" },
      domProps: { value: _vm.point },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.point = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }