<template>
  <ul class="nav nav-tabs">
    <li
      v-for="{ type, text } in tabs"
      :key="type"
      :class="{ active: type === active }"
      @click="() => setActive(type)"
    >
      <a>{{ text }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['active', 'tabs', 'setActive'],
  mounted() {
    const { active, tabs, setActive } = this;
    if (active) return;

    const hash = location.hash.replace(/^#/, "");
    const hashAvalible = tabs.map(({ type }) => type).includes(hash);
    setActive(hashAvalible ? hash : tabs[0].type);
  },
};
</script>

<style scoped lang="scss">
ul {
  margin-top: 20px;
}

a {
  cursor: pointer;
  transition: border 0.3s, background 0.3s;
}

li:not(.active) a:hover {
  background: rgba(255, 255, 255, 0.2);
}
</style>
