<template>
  <div class="field-box left even">
    <div class="background" :style="servingStyle" />
    <img src="/assets/images/field.svg" alt="" />

    <field-player-button
      v-for="{ team, order, player, style } in players"
      :key="`${team}${order}`"
      :team="team"
      :order="order"
      :player="player"
      :started="started"
      :customStyle="style"
      :onClick="onClick"
    />
  </div>
</template>

<script>
import FieldPlayerButton from './FieldPlayerButton';

export default {
  props: ['team', 'firstServing', 'history', 'redStartScore', 'blueStartScore', 'started', 'onClick'],
  components: { FieldPlayerButton },

  computed: {
    isSingle() {
      const { team, started } = this;
      if (!started) return false;
      return !team.blue1 || !team.blue2 || !team.red1 || !team.red2;
    },

    needSwitchField() {
      const { history } = this;
      return history.filter(({ type }) => type === 'field').length % 2 === 1;
    },

    needSwitchSides() {
      const { getChangeSideNumber } = this;

      return {
        red: getChangeSideNumber('red') % 2 === 1,
        blue: getChangeSideNumber('blue') % 2 === 1,
      };
    },

    currentServing() {
      const { firstServing, history } = this;
      const scoreChanges = history.filter(({ type }) => type === 'score');
      return (scoreChanges[scoreChanges.length - 1] || {}).team || firstServing;
    },

    servingStyle() {
      const { needSwitchField, currentServing, history } = this;
      const blueServing = currentServing === 'blue';

      const start = this[`${currentServing}StartScore`] || 0;
      const current = parseInt(start, 10) + history.filter(({ team }) => team === currentServing).length;

      const currentEven = current % 2 === 0;

      const atLeft = blueServing !== needSwitchField;
      const atTop = atLeft !== currentEven;

      return {
        left: atLeft ? 0 : '50%',
        top: atTop ? 0 : '50%',
      };
    },

    players() {
      const { team, getStyle } = this;

      return [
        { t: 'red', order: 1 },
        { t: 'red', order: 2 },
        { t: 'blue', order: 1 },
        { t: 'blue', order: 2 },
      ].map(({ t, order }) => ({
        team: t,
        order,
        player: team[`${t}${order}`],
        style: getStyle(t, order),
      }));
    },
  },

  methods: {
    getChangeSideNumber(team) {
      const { firstServing, history } = this;
      const sideChanges = history
        .filter(({ type }) => type === 'score')
        .reduce(
          ({ last, sum }, { team: t }) => {
            const add = t === team && t === last;
            return { last: t, sum: sum + (add ? 1 : 0) };
          },
          { last: firstServing, sum: 0 }
        );

      return sideChanges.sum;
    },

    getStyle(team, order) {
      const {
        started,
        isSingle,
        needSwitchField,
        history,
        currentServing,
        needSwitchSides,
      } = this;

      if (started && isSingle && order === 2) return { display: 'none' };

      const start = this[`${currentServing}StartScore`] || 0;
      const current = parseInt(start, 10) + history.filter(({ team }) => team === currentServing).length;
      const currentEven = current % 2 === 0;

      const atLeft = (team === 'blue') !== needSwitchField;

      const atTop = (started && isSingle)
        ? atLeft !== currentEven
        : (atLeft !== needSwitchSides[team]) !== (order === 1);

      return {
        left: atLeft ? 0 : '50%',
        right: atLeft ? '50%' : 0,
        top: atTop ? 0 : '50%',
        bottom: atTop ? '50%' : 0,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../_colors.scss';

.field-box {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  transform-style: preserve-3d;

  @media (min-width: 360px) {
    transform:
      translate(0, 360px)
      perspective(200px)
      translate3d(0, -360px, 0)
      rotate3d(1, 0, 0, 10deg);
  }
}

.background {
  position: absolute;
  width: 50%;
  height: 50%;
  background:
    rgba(255, 219, 0, 0.8)
    url('../../../images/ball.png') right 5px bottom 5px no-repeat;
  background-size: 25px 35px;
  opacity: 0.8;
  transition: top 0.3s, left 0.3s;
  will-change: top, left;
}

img {
  z-index: 1;
  display: block;
}
</style>
