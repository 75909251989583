var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "player",
      class: { [_vm.layout]: true },
      attrs: { "data-gender": _vm.gender },
      on: { click: _vm.onPlayerClick },
    },
    [
      _c("img", { attrs: { alt: _vm.player.name, src: _vm.avatar } }),
      _vm._v(" "),
      _c("div", { staticClass: "info" }, [
        _vm._v(_vm._s(_vm.player.name)),
        _c("br"),
        _vm._v("(" + _vm._s(_vm.player.point) + ")"),
      ]),
      _vm._v(" "),
      _vm.player.selected
        ? _c("div", { staticClass: "check-icon", class: `${_vm.layout}` })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }