var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { class: `${_vm.customClass}`, on: { click: _vm.onClick } },
    [
      _vm.icon
        ? _c("span", {
            class: `glyphicon glyphicon-${_vm.icon} ${_vm.iconClass}`,
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.text ? _c("span", [_vm._v(_vm._s(_vm.text))]) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }