var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row margin-top-2" }, [
    _c("div", { staticClass: "col-xs-6" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.staticYear,
              expression: "staticYear",
            },
          ],
          staticClass: "form-control",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.staticYear = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.years, function (y) {
          return _c("option", { key: y, domProps: { value: y } }, [
            _vm._v(_vm._s(y)),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xs-6" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.groupId,
              expression: "groupId",
            },
          ],
          staticClass: "form-control",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.groupId = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.groups, function ({ id, name }) {
          return _c("option", { key: id, domProps: { value: id } }, [
            _vm._v(_vm._s(name)),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "player_info" }, [
      _c(
        "div",
        { staticClass: "chart_container margin-top-3" },
        [
          _c("v-frappe-chart", {
            attrs: {
              type: "bar",
              labels: _vm.labels,
              data: _vm.datasets,
              colors: ["red"],
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "chart_container margin-top-3",
          staticStyle: { overflow: "scroll" },
        },
        [
          _c("v-frappe-chart", {
            attrs: {
              type: "heatmap",
              data: _vm.heatmapDatasets,
              start: _vm.heatmapStart,
              end: _vm.heatmapEnd,
              height: 200,
              radius: 1,
              discreteDomains: 1,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }