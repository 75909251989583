<template>
  <button class="btn" :class="customeClass" @click="onClick">
    <span
      v-if="icon"
      class="glyphicon"
      :class="`glyphicon-${icon} ${iconClass || ''}`"
    />
    <span v-if="btnText">{{ btnText }}</span>
  </button>
</template>

<script>
export default {
  props: [
    'customeClass',
    'icon',
    'iconClass',
    'btnText',
    'withCancel',
    'type',
    'title',
    'text',
    'confirmUrl',
    'confirmText',
    'cancelUrl',
    'cancelText',
  ],

  methods: {
    onClick() {
      const {
        withCancel,
        type,
        title = "",
        text = "",
        confirmUrl,
        confirmText = "確認",
        cancelUrl,
        cancelText = "取消",
      } = this;

      this.$swal({
        showCancelButton: withCancel,
        icon: type,
        title,
        text,
        reverseButtons: true,
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
      }).then(({ value, dismiss }) => {
        if (value) {
          window.location.href = confirmUrl;
          return;
        }

        if (dismiss === 'cancel' && cancelUrl) {
          window.location.href = cancelUrl;
        }
      });
    },
  },
};
</script>


<style scoped>
button {
  display: inline-flex;
  align-items: center;
}

.glyphicon {
  top: initial;
  bottom: initial;
  margin: 0 3px 0 0;
}
</style>
