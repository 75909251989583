var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-group" }, [
    _c("div", { staticClass: "input-group-addon" }, [
      _vm._v("原始"),
      _c("span", { staticClass: "hidden-xs" }, [_vm._v("積分")]),
      _vm._v(" "),
      _c("span", { attrs: { id: "player-old-point" } }, [
        _vm._v(_vm._s(_vm.old_point)),
      ]),
    ]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.adjust_point,
          expression: "adjust_point",
        },
      ],
      staticClass: "form-control",
      attrs: { type: "number", name: "adjust_point", id: "adjust_point" },
      domProps: { value: _vm.adjust_point },
      on: {
        change: _vm.update_point,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.adjust_point = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "input-group-addon" }, [
      _vm._v("調整後"),
      _c("span", { staticClass: "hidden-xs" }, [_vm._v("積分")]),
      _vm._v(" "),
      _c("span", { attrs: { id: "player-new-point" } }, [
        _vm._v(_vm._s(_vm.new_point)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }