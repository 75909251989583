<template>
  <label
    class="player"
    :class="{ [layout]: true }"
    :data-gender="gender"
    @click="onPlayerClick"
  >
    <img :alt="player.name" :src="avatar" />
    <div class="info">{{ player.name }}<br />({{ player.point }})</div>

    <div v-if="player.selected" class="check-icon" :class="`${layout}`" />
  </label>
</template>

<script>
export default {
  props: ['layout', 'player', 'onClick'],
  computed: {
    avatar() {
      const { avatar } = this.player;
      if (avatar) return avatar;
      return '/assets/images/avatar-unknown.png';
    },
    gender() {
      return this.player.gender || '';
    },
  },
  methods: {
    onPlayerClick() {
      if (this.onClick) this.onClick(this.player.id);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../_colors.scss';

.player {
  position: relative;
  z-index: 0;
  margin: 0;
  background: $gray-light;
  overflow: hidden;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: $gray-dark;
  }

  &[data-gender]::before {
    content: '';
    background: center no-repeat;
    background-size: contain;
  }

  &[data-gender='male']::before {
    background-image: url('../../../images/icons/male.png');
  }
  &[data-gender='female']::before {
    background-image: url('../../../images/icons/female.png');
  }

  &.grid {
    padding: 0 0 100%;
    border-radius: 10px;

    &[data-gender]::before {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 2;
      width: 1.5em;
      height: 1.5em;
      filter: drop-shadow(0 0 2px $black);
    }
  }

  &.list {
    display: flex;
    height: 50px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    will-change: transform;

    &[data-gender]::before {
      width: 1em;
      height: 40px;
      margin: 0 0 0 -1em;
      background-position: center bottom;
      transform: translate(8px, 0);
    }
  }
}

img {
  object-fit: cover;

  .grid & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .list & {
    width: 40px;
    height: 40px;
    order: -1;
    margin-right: 5px;
    border-radius: 50%;
  }
}

.info {
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;

  .grid & {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    padding: 20px 0 6px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5),
      transparent
    );
  }
}

.check-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 50%;
    display: block;
    width: 20px;
    height: 10px;
    border-left: 3px solid $white;
    border-bottom: 3px solid $white;
  }

  &.list::after {
    transform: translate(50%, -100%) rotate(-40deg);
  }
  &.grid::after {
    transform: translate(50%, -85%) rotate(-40deg) scale(1.3);
  }
}
</style>
