<template>
  <form :action="formAction" accept-charset="UTF-8" method="post">
    <input type="hidden" name="authenticity_token" :value="authenticityToken">
    <div class="hide">
      <input type="hidden" name="schedule_id" :value="id" />
      <input
        v-for="s in selectedList"
        :key="s"
        :value="s"
        checked
        type="checkbox"
        name="player_ids[]"
      />
    </div>

    <player-selector :players="players" :selected="selectedList" :onClick="onClick" />

    <div v-if="editable" class="tabpanel-footer space-between">
      <div>
        目前已選擇 <span id="count">{{ selectedList.length }}</span> 位選手
        (
          男性 <span id="count-male">{{ maleCount }}</span> 人
          /
          女性 <span id="count-female">{{ femaleCount }}</span> 人
          /
          未設定 <span id="count-unknown">{{ unknownCount }}</span> 人
        )
      </div>
      <button type="submit" class="btn btn-warning bg-orange">
        <span class="glyphicon glyphicon-saved"></span> 加入選手
      </button>
    </div>
  </form>
</template>

<script>
import PlayerSelector from "../Player/PlayerSelector";

export default {
  components: { PlayerSelector },

  props: ["id", "editable", "players", "selected"],

  data() {
    return {
      selectedList: this.selected || [],
      authenticity_token: null,
    };
  },

  methods: {
    onClick(id) {
      const { editable, selectedList } = this;

      if (!editable) return;

      const listWithoutThis = selectedList.filter(v => v !== id);

      if (listWithoutThis.length < selectedList.length) {
        this.selectedList = listWithoutThis;
        return;
      }
      this.selectedList = [...listWithoutThis, id];
    },
  },

  computed: {
    formAction() {
      return `/schedules/${this.id}/players/import`;
    },
    selectedPlayers() {
      const { selectedList, players } = this;
      return players.filter(({ id }) => selectedList.includes(id));
    },
    maleCount() {
      return this.selectedPlayers.filter(({ gender }) => gender === "male").length;
    },
    femaleCount() {
      return this.selectedPlayers.filter(({ gender }) => gender === "female").length;
    },
    unknownCount() {
      const { selectedPlayers, maleCount, femaleCount } = this;
      return this.selectedPlayers.length - maleCount - femaleCount;
    },
    authenticityToken() {
      var meta = document.querySelector('meta[name=csrf-token]');
      var token = meta && meta.content;
      return token;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../_colors.scss";

.tabpanel-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 20px 10px;
  background: $gray;

  > *:last-child {
    margin-left: 10px;
  }
}
</style>
