var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group-card" },
    [
      _vm.status
        ? _c(
            "div",
            { staticClass: "status", class: `btn-sm text-${_vm.status.color}` },
            [
              _c("span", { class: `glyphicon glyphicon-${_vm.status.icon}` }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.status.text))]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("a", { staticClass: "group-link", attrs: { href: _vm.url } }, [
        _c("span", { class: _vm.processing ? "bg-red" : "" }, [
          _vm._v(_vm._s(_vm.schedule.group_name[0])),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            [
              _c("strong", [_vm._v(_vm._s(_vm.schedule.title))]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("small", [_vm._v(_vm._s(_vm.schedule.group_name))]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.pending
                ? [
                    _c("small", [
                      _c("span", {
                        staticClass: "glyphicon glyphicon-calendar",
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.schedule.time))]),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.schedule.location != null && _vm.schedule.location != ""
                ? [
                    _c("small", [
                      _c("span", { staticClass: "glyphicon glyphicon-flag" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.schedule.location))]),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  "\n          報名人數：\n          " +
                    _vm._s((_vm.dataPlayers || _vm.schedule.players).length) +
                    " /\n          " +
                    _vm._s(
                      _vm.schedule.player_limit > 0
                        ? _vm.schedule.player_limit
                        : "-"
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.pending && _vm.schedule.player_limit > 0
                ? [
                    _c("br"),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "候補人數：" +
                          _vm._s((_vm.dataQueued || _vm.schedule.queued).length)
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _vm.hasQueued
          ? _c("div", { staticClass: "card-stamp text-yellow" }, [
              _vm._v("候補中"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.hasJoined
          ? _c("div", { staticClass: "card-stamp text-red" }, [
              _vm._v("已報名"),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.showActions
        ? _c("action-button", {
            attrs: {
              btnText: _vm.button.btnText,
              icon: _vm.button.icon,
              customeClass: _vm.button.customeClass,
              action: _vm.button.action,
              onFinish: _vm.onStatusChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }