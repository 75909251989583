// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/ball.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".field-box[data-v-41d06ab9]{position:relative;max-width:600px;margin:0 auto;transform-style:preserve-3d}@media(min-width: 360px){.field-box[data-v-41d06ab9]{transform:translate(0, 360px) perspective(200px) translate3d(0, -360px, 0) rotate3d(1, 0, 0, 10deg)}}.background[data-v-41d06ab9]{position:absolute;width:50%;height:50%;background:rgba(255,219,0,.8) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") right 5px bottom 5px no-repeat;background-size:25px 35px;opacity:.8;transition:top .3s,left .3s;will-change:top,left}img[data-v-41d06ab9]{z-index:1;display:block}", "",{"version":3,"sources":["webpack://./app/assets/javascripts/components/Scorer/Field.vue"],"names":[],"mappings":"AAGA,4BACE,iBAAA,CACA,eAAA,CACA,aAAA,CACA,2BAAA,CAEA,yBANF,4BAOI,mGACE,CAAA,CAON,6BACE,iBAAA,CACA,SAAA,CACA,UAAA,CACA,oGACE,CAEF,yBAAA,CACA,UAAA,CACA,2BAAA,CACA,oBAAA,CAGF,qBACE,SAAA,CACA,aAAA","sourcesContent":["\n@import '../../_colors.scss';\n\n.field-box {\n  position: relative;\n  max-width: 600px;\n  margin: 0 auto;\n  transform-style: preserve-3d;\n\n  @media (min-width: 360px) {\n    transform:\n      translate(0, 360px)\n      perspective(200px)\n      translate3d(0, -360px, 0)\n      rotate3d(1, 0, 0, 10deg);\n  }\n}\n\n.background {\n  position: absolute;\n  width: 50%;\n  height: 50%;\n  background:\n    rgba(255, 219, 0, 0.8)\n    url('../../../images/ball.png') right 5px bottom 5px no-repeat;\n  background-size: 25px 35px;\n  opacity: 0.8;\n  transition: top 0.3s, left 0.3s;\n  will-change: top, left;\n}\n\nimg {\n  z-index: 1;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
