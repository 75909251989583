<template>
  <div class="group-card">
    <div v-if="status" class="status" :class="`btn-sm text-${status.color}`">
      <span :class="`glyphicon glyphicon-${status.icon}`"></span>
      <span>{{ status.text }}</span>
    </div>

    <a :href="url" class="group-link">
      <span :class="processing ? 'bg-red' : ''">{{ schedule.group_name[0] }}</span>

      <div>
        <div>
          <strong>{{ schedule.title }}</strong>
          <br>
          <small>{{ schedule.group_name }}</small>
          <br>
          <template v-if="pending">
            <small>
              <span class="glyphicon glyphicon-calendar"></span>
              <span>{{ schedule.time }}</span>
            </small>
            <br>
          </template>
          <template v-if="schedule.location != null && schedule.location != ''">
            <small>
              <span class="glyphicon glyphicon-flag"></span>
              <span>{{ schedule.location }}</span>
            </small>
            <br>
          </template>

          <small>
            報名人數：
            {{ (dataPlayers || schedule.players).length }} /
            {{ schedule.player_limit > 0 ? schedule.player_limit : '-' }}
          </small>

          <template v-if="pending && schedule.player_limit > 0">
            <br>
            <small>候補人數：{{ (dataQueued || schedule.queued).length }}</small>
          </template>
        </div>
      </div>

      <div v-if="hasQueued" class="card-stamp text-yellow">候補中</div>
      <div v-if="hasJoined" class="card-stamp text-red">已報名</div>
    </a>

    <action-button
      v-if="showActions"
      :btnText="button.btnText"
      :icon="button.icon"
      :customeClass="button.customeClass"
      :action="button.action"
      :onFinish="onStatusChange"
    />
  </div>

</template>

<script>
import ActionButton from '../Button/ActionButton';

export default {
  components: { ActionButton },

  props: ['schedule', 'players'],

  data() {
    return {
      dataAttend: undefined,
      dataQueued: undefined,
      dataPlayers: undefined,
    };
  },

  computed: {
    processing() {
      return this.schedule.state === 'processing';
    },
    pending() {
      return this.schedule.state === 'pending';
    },
    status() {
      const { processing, pending } = this;
      if (processing) return { text: '進行中', color: 'red', icon: 'record' };
      if (pending) return { text: '尚未開始，接受報名中', color: 'green', icon: 'time' };
    },

    player() {
      const { schedule: { season_id: id }, players } = this;
      return players.filter(({ season_id }) => season_id === id)[0] || {};
    },
    hasJoined() {
      const { dataPlayers, schedule: { players }, player: { id, ...o } } = this;
      return (dataPlayers || players).filter(({ id: i }) => i === id).length > 0;
    },
    hasQueued() {
      const { dataQueued, schedule: { queued }, player: { id } } = this;
      return (dataQueued || queued).filter(({ id: i }) => i === id).length > 0;
    },
    url() {
      const { schedule, pending } = this;
      if (pending) return `/schedules/${schedule.id}/info`;
      return `/schedules/${schedule.id}/players/result`;
    },

    showActions() {
      const { processing, pending, hasJoined, hasQueued, dataPlayers, schedule: { player_limit, players } } = this;
      const hasAttend = hasJoined || hasQueued;

      if (processing && player_limit > 0 && player_limit <= (dataPlayers || players).length && hasAttend == false) return false;
      return processing || pending;
    },

    button() {
      const customeClass = 'btn-link text-white';
      const { pending, hasJoined, hasQueued, dataPlayers, schedule: { id, player_limit, players } } = this;

      const hasAttend = hasJoined || hasQueued;
      if (hasAttend) {
        return {
          customeClass,
          icon: 'share-alt',
          btnText: pending ? '取消報名' : '離開',
          action: `/in/schedules/${id}/sign_out`
        };
      }

      const isLimitArrived = player_limit > 0 && player_limit <= (dataPlayers || players).length;
      const actionText = isLimitArrived ? '加入候補' : '報名';

      return {
        customeClass,
        icon: 'plus',
        btnText: `${pending ? '' : '現場'}${actionText}`,
        action: `/in/schedules/${id}/register`,
      };
    },
  },

  methods: {
    onStatusChange(data) {
      this.dataQueued = data.queued;
      this.dataPlayers = data.players;
    },
  },
}
</script>

<style lang="scss" scoped>
.group-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.status {
  width: 100%;
}

.group-link {
  position: relative;
  flex-grow: 1;
  width: 50%;
}

button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-right: -15px;

  :deep(.glyphicon) {
    margin: 4px 0 2px !important;
  }

  :deep(span) {
    margin: 0 !important;
    writing-mode: vertical-rl;
    letter-spacing: 1px;
  }
}
</style>
