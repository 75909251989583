<template>
  <div class="tab-pane active">
    <h4 class="text-center">輸入選手與比分</h4>

    <div class="team">
      <div class="players">
        <player team="blue" :order="1" :player="team.blue1" :onClick="onClick" />
        <player team="blue" :order="2" :player="team.blue2" :onClick="onClick" />
      </div>
      <Slider
        color="#214fb0"
        :min="blueStartScore ?? 0"
        :max="maxScore"
        :value="blueScore"
        :onChange="changeBlueScore"
      />
    </div>
    <div class="team">
      <Slider
        color="#dd4745"
        :min="redStartScore ?? 0"
        :max="maxScore"
        :value="redScore"
        :onChange="changeRedScore"
      />
      <div class="players">
        <player team="red" :order="1" :player="team.red1" :onClick="onClick" />
        <player team="red" :order="2" :player="team.red2" :onClick="onClick" />
      </div>
    </div>

    <p class="text-center">
      <button class="btn btn-lg btn-success bg-green" @click="startAndSubmit">
        送出對戰成績
      </button>
    </p>
  </div>
</template>

<script>
import axios from 'axios';
import PlayerButton from './PlayerButton';
import Slider from '../FormItems/Slider';

export default {
  props: ['race', 'team', 'rounds', 'matchScore', 'blueStartScore', 'redStartScore', 'onClick', 'onChange', 'onSubmit', 'redScore', 'blueScore'],
  components: { player: PlayerButton, Slider },

  computed: {
    maxScore() {
      const { matchScore } = this;
      return +matchScore === 21 ? 30 : matchScore;
    },
  },

  methods: {
    startAndSubmit() {
      this.start().then(() => {
        this.onSubmit();
      });
    },
    changeRedScore({ target: { value } }) {
      this.onChange('redScore', +value);
    },
    changeBlueScore({ target: { value } }) {
      this.onChange('blueScore', +value);
    },
    start() {
      const { race, team, rounds, matchScore, blueStartScore, redStartScore, onChange } = this;

      const red = [team.red1, team.red2].filter((p) => p).length;
      const blue = [team.blue1, team.blue2].filter((p) => p).length;

      if (!red || red !== blue) {
        this.$swal({ icon: 'error', title: '選手數目不對' });
        return;
      }

      const singleRed = team.red1 || team.red2;
      const singleBlue = team.blue1 || team.blue2;

      if (red === 1) {
        onChange('red1', singleRed.id);
        onChange('red2', undefined);
        onChange('blue1', singleBlue.id);
        onChange('blue2', undefined);
      }

      const { id, schedule_id } = race;

      const formData = new FormData();
      formData.append('race_id', id);
      if (schedule_id) formData.append('schedule_id', schedule_id);

      if (red === 1) {
        formData.append('red[]', singleRed.id);
        formData.append('blue[]', singleBlue.id);
      } else {
        formData.append('red[]', team.red1.id);
        formData.append('red[]', team.red2.id);
        formData.append('blue[]', team.blue2.id);
        formData.append('blue[]', team.blue1.id);
      }
      formData.append('rounds', rounds);
      formData.append('blue_start_score', blueStartScore);
      formData.append('red_start_score', redStartScore);
      formData.append('match_score', matchScore);

      return axios.post(`/in/races/start/${id}`, formData);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../_colors.scss';

h4 {
  margin-top: 20px;
}

.team {
  margin: 15px 0;
  padding: 20px 10px 15px;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}

.players {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

select {
  display: inline-block;
  width: 100px;
}
</style>
