var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "button",
      class: `text-${_vm.team}`,
      on: { click: _vm.onPlayerClick },
    },
    [
      !_vm.player
        ? _c("span", { staticClass: "glyphicon glyphicon-user" })
        : [
            _c("img", { attrs: { alt: _vm.player.name, src: _vm.avatar } }),
            _vm._v(" "),
            _c("div", { staticClass: "info full" }, [
              _vm._v(_vm._s(_vm.player.name)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "glyphicon glyphicon-remove-sign" }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }