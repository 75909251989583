<template>
  <small :class="`text--${state}`">
    {{ texts[state] || state }}
  </small>
</template>

<script>
const texts = {
  left: '已離開',
  finished: '已離開',
  registered: '未報到',
  waiting: '待機中',
  racing: '比賽中',
  paused: '暫停',
};

export default {
  props: ['id', 'state'],
  data() { return { texts }; },
};
</script>

<style scoped lang="scss">
.text--registered { color: #888 }
.text--left { color: #888 }
.text--finished { color: #888 }
.text--waiting { color: #c9d800 }
.text--racing { color: #31b81c }
.text--paused { color: #d95c71 }
</style>
