var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.list, function (s) {
      return _c("schedule-li", {
        key: s.id,
        attrs: { schedule: s, players: _vm.userPlayers },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }