var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editing
    ? _c(
        "div",
        { staticClass: "edit-avatar" },
        [
          _c("vue-croppie", {
            ref: "croppieRef",
            attrs: {
              enableOrientation: true,
              viewport: { width: 250, height: 250, type: "square" },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "buttons text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default bg-white",
                on: { click: _vm.cancel },
              },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success bg-green",
                on: { click: _vm.onFinish },
              },
              [_vm._v("完成編輯")]
            ),
          ]),
        ],
        1
      )
    : _c("div", { staticClass: "update-avatar" }, [
        _c("img", {
          staticClass: "avatar",
          attrs: { src: _vm.src },
          on: { error: _vm.onError },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "overlay text-gray" }, [
          _c("span", { staticClass: "glyphicon glyphicon-camera" }),
          _vm._v(" "),
          _c("span", [_vm._v("更改")]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.onFileChange },
          }),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }