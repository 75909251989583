<template>
  <button :class="`${customClass}`" @click="onClick">
    <span v-if="icon" :class="`glyphicon glyphicon-${icon} ${iconClass}`" />
    <span v-if="text">{{ text }}</span>
  </button>
</template>

<script>
import axios from 'axios';

export default {
  props: [
    'customClass',
    'icon',
    'iconClass',
    'text',
    'url',
    'params',
    'onFinish',
    'method',
  ],

  methods: {
    onClick() {
      const { method, url, params, onFinish } = this;

      const action = axios[method || 'put'];
      return action(url, params).then((res) => {
        if (onFinish) onFinish(res, params);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  display: inline-flex;
  align-items: center;
}

.glyphicon {
  margin: 0 5px 2px 0;

  &:last-child {
    margin: 0 0 2px;
  }
}
</style>
