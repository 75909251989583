import Vue from "vue/dist/vue.esm";
import swal from "vue-sweetalert2";
import VueClipboard from 'vue-clipboard2';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueCroppie from 'vue-croppie';
import Chart from 'vue-frappe-chart'
import ActionCableVue from 'actioncable-vue';
import 'croppie/croppie.css'
import "sweetalert2/dist/sweetalert2.min.css";

import SweetAlert from "../components/SweetAlert";
import GenderButtons from "../components/Button/GenderButtons";
import ActiveButtons from "../components/Button/ActiveButtons";
import ConfirmButton from "../components/Button/ConfirmButton";
import PromptButton from "../components/Button/PromptButton";
import ActionButton from "../components/Button/ActionButton";
import UserChart from "../components/Chart/UserChart";
import BatchPlayersForm from "../components/Group/BatchPlayersForm";
import PairForm from "../components/Form/PairForm";
import SchedulePlayersForm from "../components/Form/SchedulePlayersForm";
import SchedulePlayersStateForm from "../components/Form/SchedulePlayersStateForm";
import ScheduleTeamsForm from "../components/Form/ScheduleTeamsForm";
import ScheduleApartForm from "../components/Form/ScheduleApartForm";
import PointUpdater from "../components/Player/PointUpdater";
import Invite from "../components/Form/Invite";
import AvatarEditor from "../components/Form/AvatarEditor";
import Scorer from "../components/Scorer/Scorer";
import Field from "../components/Scorer/Field";
import Race from "../components/Race";
import Live from "../components/Race/Live";
import RaceResult from "../components/RaceResult";
import PairResult from "../components/PairResult";
import TabsHeader from "../components/Tabs/TabsHeader";
import Refresher from "../components/Trigger/Refresher";
import ScheduleList from "../components/Schedule/ScheduleList";
import ScheduleRaceTable from "../components/Schedule/ScheduleRaceTable";
import ScheduleFieldTable from "../components/Schedule/ScheduleFieldTable";
import NotificationBox from "../components/Notification/Box";

Vue.use(swal);
Vue.use(VueClipboard);
Vue.use(VueCroppie);
Vue.use(Chart);
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env.WEB_SOCKET_URL,
  connectImmediately: true,
});

document.addEventListener("DOMContentLoaded", () => {
  Vue.component(VueQrcode.name, VueQrcode);

  new Vue({
    el: "#vue-main",
    data() {
      return {
        activeTab: "",
      };
    },
    methods: {
      setActiveTab(tab) {
        this.activeTab = tab;
      },
    },
    components: {
      SweetAlert,
      GenderButtons,
      ActiveButtons,
      ConfirmButton,
      PromptButton,
      ActionButton,
      TabsHeader,
      UserChart,
      PairForm,
      SchedulePlayersForm,
      SchedulePlayersStateForm,
      ScheduleTeamsForm,
      ScheduleApartForm,
      PointUpdater,
      BatchPlayersForm,
      Invite,
      AvatarEditor,
      Race,
      RaceResult,
      PairResult,
      Refresher,
      Live,
      vueScorer: Scorer,
      vueField: Field,
      ScheduleList,
      ScheduleRaceTable,
      ScheduleFieldTable,
      NotificationBox,
    },
  });
})
