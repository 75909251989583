<template>
  <div class="button" :class="`text-${team}`" @click="onPlayerClick">
    <span v-if="!player" class="glyphicon glyphicon-user" />
    <template v-else>
      <img :alt="player.name" :src="avatar" />
      <div class="info full">{{ player.name }}</div>
      <span class="glyphicon glyphicon-remove-sign" />
    </template>
  </div>
</template>

<script>
export default {
  props: ['team', 'order', 'player', 'onClick'],
  computed: {
    avatar() {
      const { avatar } = this.player;
      if (avatar) return avatar;
      return '/assets/images/avatar-unknown.png';
    },
  },
  methods: {
    onPlayerClick() {
      const { team, order, onClick } = this;
      onClick(`${team}${order}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../_colors.scss';

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: filter 0.3s;

  &:hover {
    filter: brightness(1.5);
  }
}

.glyphicon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-radius: 50%;
}

.glyphicon-user {
  width: 60px;
  height: 60px;
  font-size: 30px;
}

.glyphicon-remove-sign {
  position: absolute;
  top: 5px;
  right: 5px;
  border-color: #fff;
  font-size: 20px;
  background: #fff;
}

img {
  width: 60px;
  height: 60px;
  background: $white;
  border-radius: 50%;
}

.info {
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 5px;
  filter: brightness(1.5);
}
</style>
