<template></template>

<script>
const toastSettings = {
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
};

export default {
  props: ['full', 'icon', 'title', 'text'],

  mounted() {
    const { icon, title, text, full } = this;
    const otherSettings = full === undefined ? toastSettings: {};
    setTimeout(() => this.$swal({ icon, title, text, ...otherSettings }), 1500);
  },
};
</script>
