var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c("a", { attrs: { href: _vm.notification.link } }, [
      _vm.notification.image
        ? _c("img", { attrs: { alt: "", src: _vm.notification.image } })
        : _c("b", [_vm._v(_vm._s(_vm.notification.word))]),
      _vm._v(" "),
      _c("p", [
        _c("span", [_vm._v(_vm._s(_vm.notification.message))]),
        _vm._v(" "),
        _c("small", [_vm._v(_vm._s(_vm.notification.time_words))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }