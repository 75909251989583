<template>
  <div>
    <schedule-li v-for="s in list" :key="s.id" :schedule="s" :players="userPlayers" />
  </div>
</template>

<script>
import ScheduleLi from './ScheduleLi';

export default {
  components: { ScheduleLi },
  props: ['list', 'userPlayers'],
}
</script>

<style lang="scss" scoped>
</style>
