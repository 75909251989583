var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "nav nav-tabs" },
    _vm._l(_vm.tabs, function ({ type, text }) {
      return _c(
        "li",
        {
          key: type,
          class: { active: type === _vm.active },
          on: { click: () => _vm.setActive(type) },
        },
        [_c("a", [_vm._v(_vm._s(text))])]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }