var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "record" }, [
    !_vm.pure
      ? _c("div", { staticClass: "record-head space-between" }, [
          _c("div", [_vm._v(_vm._s(_vm.date))]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.adjust_point))]),
          _vm._v(" "),
          _c("div", [
            _vm.displayHasDetail
              ? _c("a", { attrs: { href: _vm.detailUrl } }, [
                  _c("span", {
                    staticClass: "glyphicon glyphicon-play text-green",
                  }),
                  _vm._v(" 比賽過程\n      "),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "record-card" }, [
      _c(
        "div",
        { staticClass: "record-team" },
        _vm._l(_vm.winners, function (item) {
          return _c(
            "a",
            {
              key: item.id,
              class: _vm.getPlayerClass(item.id, true),
              attrs: { href: _vm.playerUrl(item) },
            },
            [
              _c("img", { attrs: { src: item.avatar_url } }),
              _vm._v(" "),
              _c("div", { staticClass: "info full" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "record-score" }, [
        _c("div", { staticClass: "score" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.winnerScores) } }),
          _vm._v(" "),
          _c("div", { staticClass: "point hidden-xs" }, [
            _vm._v("( " + _vm._s(_vm.winnerPoint) + " )"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("-")]),
        _vm._v(" "),
        _c("div", { staticClass: "score" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.loserScores) } }),
          _vm._v(" "),
          _c("div", { staticClass: "point hidden-xs" }, [
            _vm._v("( " + _vm._s(_vm.loserPoint) + " )"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "record-team" },
        _vm._l(_vm.losers, function (item) {
          return _c(
            "a",
            {
              key: item.id,
              class: _vm.getPlayerClass(item.id, false),
              attrs: { href: _vm.playerUrl(item) },
            },
            [
              _c("img", { attrs: { src: item.avatar_url } }),
              _vm._v(" "),
              _c("div", { staticClass: "info full" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }