<template>
  <div></div>
</template>

<script>
import axios from 'axios';

export default {
  props: [
    'method',
    'url',
    'seconds',
  ],

  mounted() {
    const { method, url, seconds } = this;
    let distance = seconds;

    var x = setInterval(function() {
      distance = distance - 1

      if (distance < -2) {
        axios({
          method: method,
          url: url
        })
        .then(() => {
          window.location.reload();
        })
        .finally(() => {});
      }
    }, 1000);
  },
};
</script>
