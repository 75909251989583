<template>
  <div v-if="editing" class="edit-avatar">
    <vue-croppie
      ref="croppieRef"
      :enableOrientation="true"
      :viewport="{ width: 250, height:250, 'type':'square' }"
    />
    <div class="buttons text-center">
      <button class="btn btn-default bg-white" @click="cancel">取消</button>
      <button class="btn btn-success bg-green" @click="onFinish">完成編輯</button>
    </div>
  </div>

  <div v-else class="update-avatar">
    <img class="avatar" :src="src" @error="onError" />
    <div class="overlay text-gray">
      <span class="glyphicon glyphicon-camera"></span>
      <span>更改</span>
      <input type="file" accept="image/*" @change="onFileChange" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ["type", "id"],
  data() {
    return {
      src: null,
      editing: false,
      reader: new FileReader(),
    };
  },
  mounted() {
    this.updateAvatar();
    this.reader.onload = ({ target }) => {
      this.$refs.croppieRef.bind({ url: target.result });
    };
  },
  computed: {
    url() {
      const { type, id } = this;
      return `/in/${type}s/${id}/avatars`;
    },
  },
  methods: {
    updateAvatar() {
      const { url } = this;
      axios.get(url).then((res) => {
        this.src = res.data.data.src
      });
    },

    cancel() {
      this.editing = false;
    },

    onError() {
      this.src = "/assets/images/avatar-unknown.png";
    },

    onFileChange(event) {
      this.editing = true;

      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.reader.readAsDataURL(files[0]);
    },

    onFinish() {
      const { url } = this;
      this.$refs.croppieRef.result({
        type: 'base64',
        format: 'jpeg'
      }, output => {
        const meta = document.querySelector('meta[name=csrf-token]');
        axios.defaults.headers.common['X-CSRF-TOKEN'] = meta && meta.content;

        axios.post(url, { src: output }).then((res) => {
          this.$swal.fire({ icon: "success", title: "上傳成功" })
          this.updateAvatar();
          this.editing = false;
        }).catch(function (error) {
          this.$swal.fire({ icon: "error", text: "上傳失敗，請稍後再試" })
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-avatar {
  width: 80vw;
  height: 80vw;
  max-width: 400px;
  max-height: 400px;
  margin: 0 auto 120px;
}

.update-avatar {
  position: relative;
  width: 120px;
  margin: 10px auto 25px;
  border-radius: 50%;
  overflow: hidden;

  &:hover {
    .overlay {
      opacity: 1;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4));
  border-radius: 50%;
  opacity: 0.8;
  transition: opacity 0.3s;

  .glyphicon {
    margin: 10px 0 0;
    font-size: 30px;
    line-height: 1.5;
  }
}

.buttons {
  margin: 50px 0 0;
  padding-top: 0;
}
</style>
