<template>
  <div class="input-group" style="max-width: 200px; margin: auto;">
    <input type="text" v-model="url" class="form-control">
    <span class="input-group-btn">
      <button type="button" class="btn btn-info"
        v-clipboard:copy="url"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError">複製連結</button>
    </span>
  </div>
</template>

<script>
export default {
  props: ["url"],
  methods: {
    onCopy: function (e) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: '連結已複製'
      });
    },
    onError: function (e) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'error',
        title: '複製失敗'
      });
    }
  }
}
</script>
