var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-group",
      staticStyle: { "max-width": "200px", margin: "auto" },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.url,
            expression: "url",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "text" },
        domProps: { value: _vm.url },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.url = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "input-group-btn" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "clipboard",
                rawName: "v-clipboard:copy",
                value: _vm.url,
                expression: "url",
                arg: "copy",
              },
              {
                name: "clipboard",
                rawName: "v-clipboard:success",
                value: _vm.onCopy,
                expression: "onCopy",
                arg: "success",
              },
              {
                name: "clipboard",
                rawName: "v-clipboard:error",
                value: _vm.onError,
                expression: "onError",
                arg: "error",
              },
            ],
            staticClass: "btn btn-info",
            attrs: { type: "button" },
          },
          [_vm._v("複製連結")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }