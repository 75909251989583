var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "score-board" }, [
      _c("div", { staticClass: "score-card bg-blue" }, [
        _vm._v("\n      " + _vm._s(_vm.blue_score) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "score-card bg-red" }, [
        _vm._v("\n      " + _vm._s(_vm.red_score) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }