var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: {
        action: _vm.formAction,
        "accept-charset": "UTF-8",
        method: "post",
      },
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "authenticity_token" },
        domProps: { value: _vm.authenticityToken },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "hide" },
        [
          _c("input", {
            attrs: { type: "hidden", name: "schedule_id" },
            domProps: { value: _vm.id },
          }),
          _vm._v(" "),
          _vm._l(_vm.selectedList, function (s) {
            return _c("input", {
              key: s,
              attrs: { checked: "", type: "checkbox", name: "player_ids[]" },
              domProps: { value: s },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("player-selector", {
        attrs: {
          players: _vm.players,
          selected: _vm.selectedList,
          onClick: _vm.onClick,
        },
      }),
      _vm._v(" "),
      _vm.editable
        ? _c("div", { staticClass: "tabpanel-footer space-between" }, [
            _c("div", [
              _vm._v("\n      目前已選擇 "),
              _c("span", { attrs: { id: "count" } }, [
                _vm._v(_vm._s(_vm.selectedList.length)),
              ]),
              _vm._v(" 位選手\n      (\n        男性 "),
              _c("span", { attrs: { id: "count-male" } }, [
                _vm._v(_vm._s(_vm.maleCount)),
              ]),
              _vm._v(" 人\n        /\n        女性 "),
              _c("span", { attrs: { id: "count-female" } }, [
                _vm._v(_vm._s(_vm.femaleCount)),
              ]),
              _vm._v(" 人\n        /\n        未設定 "),
              _c("span", { attrs: { id: "count-unknown" } }, [
                _vm._v(_vm._s(_vm.unknownCount)),
              ]),
              _vm._v(" 人\n      )\n    "),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn btn-warning bg-orange", attrs: { type: "submit" } },
      [
        _c("span", { staticClass: "glyphicon glyphicon-saved" }),
        _vm._v(" 加入選手\n    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }