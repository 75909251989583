var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "selector",
      class: { fixed: _vm.fixed, active: _vm.active },
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _vm.fixed
            ? _c("div", { staticClass: "button", on: { click: _vm.onClose } }, [
                _c("span", { staticClass: "glyphicon glyphicon-remove" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "space grow" }),
          _vm._v(" "),
          _vm._l(_vm.orders, function ({ type, icon }) {
            return _c(
              "div",
              {
                key: type,
                staticClass: "button",
                class: { active: _vm.order === type },
                on: { click: () => _vm.setOrder(type) },
              },
              [_c("span", { class: `glyphicon glyphicon-${icon}` })]
            )
          }),
          _vm._v(" "),
          _c("span", { staticClass: "space" }),
          _vm._v(" "),
          _vm._l(_vm.layouts, function ({ type, icon }) {
            return _c(
              "div",
              {
                key: type,
                staticClass: "button",
                class: { active: _vm.layout === type },
                on: { click: () => _vm.setLayout(type) },
              },
              [_c("span", { class: `glyphicon glyphicon-${icon}` })]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "selector-main", class: `${_vm.layout}` },
        _vm._l(_vm.playerList, function (p) {
          return _c("player-button", {
            key: p.id,
            attrs: { player: p, layout: _vm.layout, onClick: _vm.onClick },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }