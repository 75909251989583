var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane active" }, [
    _c("h4", { staticClass: "text-center" }, [_vm._v("輸入選手與比分")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "team" },
      [
        _c(
          "div",
          { staticClass: "players" },
          [
            _c("player", {
              attrs: {
                team: "blue",
                order: 1,
                player: _vm.team.blue1,
                onClick: _vm.onClick,
              },
            }),
            _vm._v(" "),
            _c("player", {
              attrs: {
                team: "blue",
                order: 2,
                player: _vm.team.blue2,
                onClick: _vm.onClick,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("Slider", {
          attrs: {
            color: "#214fb0",
            min: _vm.blueStartScore ?? 0,
            max: _vm.maxScore,
            value: _vm.blueScore,
            onChange: _vm.changeBlueScore,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "team" },
      [
        _c("Slider", {
          attrs: {
            color: "#dd4745",
            min: _vm.redStartScore ?? 0,
            max: _vm.maxScore,
            value: _vm.redScore,
            onChange: _vm.changeRedScore,
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "players" },
          [
            _c("player", {
              attrs: {
                team: "red",
                order: 1,
                player: _vm.team.red1,
                onClick: _vm.onClick,
              },
            }),
            _vm._v(" "),
            _c("player", {
              attrs: {
                team: "red",
                order: 2,
                player: _vm.team.red2,
                onClick: _vm.onClick,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-lg btn-success bg-green",
          on: { click: _vm.startAndSubmit },
        },
        [_vm._v("\n      送出對戰成績\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }