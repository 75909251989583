<template>
  <div class="btn-group">
    <button
      v-for="event in events"
      :key="event"
      type="button"
      class="btn btn-default"
      :data-type="event"
      @click="onClick(event)"
    >
      <span
        class="glyphicon"
        :class="`glyphicon-${icons[event]}`"
        aria-hidden="true"
      />
      {{ texts[event] || event }}
    </button>
  </div>
</template>

<script>
const texts = {
  re_queue: '加入',
  check_in: '報到',
  pause: '暫停',
  back: '歸隊',
  leave: '離開',
};

const icons = {
  re_queue: 'plus',
  check_in: 'play',
  pause: 'pause',
  back: 'play',
  leave: 'stop',
};

export default {
  props: ['id', 'events', 'onChange'],

  data() { return { texts, icons }; },

  methods: {
    onClick(event) {
      const { id, onChange } = this;
      onChange(id, { event });
    },
  }
};
</script>

<style scoped lang="scss">
button {
  min-width: 65px;
  margin: 0 !important;
  border: 0;
  color: #fff;
  font-weight: 300;
  text-shadow: none;
  background: #242424;
  box-shadow: none;
  transition: color 0.3s, background 0.3s;

  &:focus {
    outline: 0 !important;
    border: 0;
    box-shadow: none !important;
  }

  &,
  &:hover {
    color: #000;
    font-weight: 400;

    &[data-type="re_queue"] { background: #888; }
    &[data-type="check_in"] { background: #31b81c; }
    &[data-type="pause"] { background: #c9d800; }
    &[data-type="back"] { background: #31b81c; }
    &[data-type="leave"] { background: #d95c71; }
  }

  .glyphicon {
    @media (max-width: 359.5px) {
      display: none;
    }
  }
}
</style>
