var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "player player-label",
      class: {
        [`text-${_vm.team}`]: !_vm.player,
        [`bg-${_vm.team}`]: !!_vm.player,
      },
      style: _vm.customStyle,
      on: { click: _vm.onPlayerClick },
    },
    [
      _vm.player
        ? [
            _c("img", { attrs: { alt: _vm.player.name, src: _vm.avatar } }),
            _vm._v(" "),
            _c("div", { staticClass: "info full" }, [
              _vm._v(_vm._s(_vm.player.name)),
            ]),
            _vm._v(" "),
            !_vm.started
              ? _c("span", {
                  staticClass: "glyphicon glyphicon-remove-sign",
                  class: { [`text-${_vm.team}`]: true },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }