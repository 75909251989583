<template>
  <div>
    <div class="score-board">
      <div class="score-card bg-blue">
        {{ blue_score }}
      </div>
      <div class="score-card bg-red">
        {{ red_score }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['race_id', 'scores'],

  data: () => ({
    blue_score: 0,
    red_score: 0
  }),

  components: { },

  computed: {
  },

  methods: {
    async getData() {
      const { race_id } = this;
      const { data: { data: { blue_score, red_score } } } = await axios.get(`/in/races/${race_id}/scores`);
      this.blue_score = blue_score;
      this.red_score = red_score;
    }
  },

  mounted() {
    const { blue, red } = this.scores;
    this.blue_score = blue;
    this.red_score = red;

    setInterval(() => {
      this.getData();
    }, 2000)
  },
};
</script>
