<template>
  <div class="record">
    <div class="record-head space-between" v-if="!pure">
      <div>{{ date }}</div>
      <div>{{ adjust_point }}</div>
      <div>
        <a :href="detailUrl" v-if="displayHasDetail">
          <span class="glyphicon glyphicon-play text-green"></span> 比賽過程
        </a>
      </div>
    </div>

    <div class="record-card">
      <div class="record-team">
        <a
          v-for="item in winners"
          :key="item.id"
          v-bind:class="getPlayerClass(item.id, true)"
          :href="playerUrl(item)"
        >
          <img :src="item.avatar_url" />
          <div class="info full">{{ item.name }}</div>
        </a>
      </div>

      <div class="record-score">
        <div class="score">
          <div v-html="winnerScores"></div>
          <div class="point hidden-xs">( {{ winnerPoint }} )</div>
        </div>
        <div>-</div>
        <div class="score">
          <div v-html="loserScores"></div>
          <div class="point hidden-xs">( {{ loserPoint }} )</div>
        </div>
      </div>

      <div class="record-team">
        <a
          v-for="item in losers"
          :key="item.id"
          v-bind:class="getPlayerClass(item.id, false)"
          :href="playerUrl(item)"
        >
          <img :src="item.avatar_url" />
          <div class="info full">{{ item.name }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmButton from './Button/ConfirmButton';

export default {
  props: [
    'pure',
    'date',
    'group_id',
    'race_id',
    'deletable',
    'has_detail',
    'blue_players',
    'red_players',
    'blue_point',
    'red_point',
    'blue_scores',
    'red_scores',
    'adjust_point',
    'server_id',
    'receiver_id',
    'me_player_id',
  ],
  mounted() {
  },
  components: {
    ConfirmButton,
  },

  computed: {
    displayDeletable() {
      return this.deletable == '1';
    },
    displayHasDetail() {
      return this.deletable == '0' && this.has_detail == '1';
    },
    redWin() {
      let red_win = 0
      let blue_win = 0
      const round = this.blue_scores.length
      for (let i=0; i<round; i++) {
        if (this.blue_scores[i] < this.red_scores[i]) {
          red_win += 1;
        } else {
          blue_win += 1;
        }
      }
      return red_win > blue_win;
    },
    winners() {
      return this.redWin ? this.red_players : this.blue_players;
    },
    losers() {
      return this.redWin ? this.blue_players : this.red_players;
    },
    winnerScores() {
      return this.redWin ? this.red_scores.join("<br>") : this.blue_scores.join("<br>");
    },
    loserScores() {
      return this.redWin ? this.blue_scores.join("<br>") : this.red_scores.join("<br>");
    },
    winnerPoint() {
      return this.redWin ? this.red_point : this.blue_point;
    },
    loserPoint() {
      return this.redWin ? this.blue_point : this.red_point;
    },
    confirmUrl() {
      return `/races/${this.race_id}/destroy`;
    },
    detailUrl() {
      return `/races/${this.race_id}`;
    },
  },

  methods: {
    playerUrl(player) {
      return `/players/${player.id}`;
    },
    getPlayerClass(player_id, is_winner) {
      let classes = "player player-label"
      if (this.me_player_id == player_id) {
        classes += " me";
      }
      if (this.server_id == player_id) {
        classes += " server";
      }
      if (this.receiver_id == player_id) {
        classes += " receiver";
      }
      if (is_winner) {
        classes += " bg-green right";
      } else {
        classes += " bg-gray-light left";
      }
      return classes;
    }
  },
};
</script>
