<template>
  <button class="btn" :class="customeClass" @click="onClick">
    <span
      v-if="icon"
      class="glyphicon"
      :class="`glyphicon-${icon} ${iconClass || ''}`"
    />
    <span v-if="btnText">{{ btnText }}</span>
  </button>
</template>

<script>
import axios from 'axios';

const toastSettings = {
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
};

export default {
  props: ['customeClass', 'icon', 'iconClass', 'btnText', 'action', 'onFinish'],

  methods: {
    async onClick() {
      const { action, btnText, onFinish } = this;

      try {
        const { data } = await axios.post(action);
        if (data.status !== 'success') throw new Error(data.message || '操作失敗，請稍後再試');
        this.$swal({ icon: "success", title: `已${btnText}`, ...toastSettings });
        onFinish(data.data);
      } catch(err) {
        this.$swal({ icon: "error", title: err.message, ...toastSettings });
      }
    },
  },
};
</script>


<style scoped>
button {
  display: inline-flex;
  align-items: center;
}

.glyphicon {
  top: initial;
  bottom: initial;
  margin: 0 3px 0 0;
}
</style>
