<template>
  <div class="row text-center">
    <div class="player-row">
      <div class="td--sm">選手</div>
      <div class="td--sm">狀態</div>
      <div class="td--lg flex-center">
        編輯{{ action.text }}
        <button class="btn btn-link text-blue-light" @click="onToggle">切換</button>
      </div>
    </div>
    <div
      v-for="{ player_id: id, name, crowd, state, available_events: events } in players"
      :key="id"
      class="player-row"
    >
      <div class="td--sm">{{ name }}</div>
      <div class="td--sm">
        <PlayerState :id="id" :state="state" />
      </div>
      <div class="td--lg">
        <PlayerEvents v-if="action.id === 'state'" :id="id" :events="events" :onChange="onChange" />
        <PlayerCrowds v-if="action.id === 'crowd'" :id="id" :crowd="crowd" :onChange="onChange" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import PlayerState from '../FormItems/PlayerState';
import PlayerEvents from '../FormItems/PlayerEvents';
import PlayerCrowds from '../FormItems/PlayerCrowds';

const actions = [{ id: 'state', text: '狀態' }, { id: 'crowd', text: '分組' }];

export default {
  props: ['id'],

  components: { PlayerState, PlayerEvents, PlayerCrowds },

  data() {
    return {
      actions,
      actionIndex: 0,
      players: [],
    };
  },

  mounted() {
    this.updatePlayers();
  },

  computed: {
    action() {
      const { actions, actionIndex } = this;
      return actions[actionIndex];
    },
  },

  methods: {
    onToggle() {
      const { actions, actionIndex } = this;
      this.actionIndex = (actionIndex + 1) % actions.length;
    },

    onChange(player_id, params) {
      const { id, updatePlayers } = this;
      return axios
        .put(`/in/schedules/${id}/players/${player_id}`, params)
        .then(({ data: { status, message } }) => {
          if (status === 'success') return;
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            icon: 'error',
            title: message
          });
        })
        .finally(updatePlayers);
    },

    updatePlayers() {
      const { id } = this;
      return axios
        .get(`/in/schedules/${id}/players`)
        .then(({ data: { data } }) => {
          // FIXME: 目前手動濾掉，之後應該改成 api 直接不傳
          this.players = (data.players || []).filter(({ state }) => state !== 'left');
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.player-row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
}

.td--sm {
  width: 80px;
}

.td--md {
  width: 120px;
}

.td--lg {
  width: 160px;
}
</style>
