var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scorer-container" },
    [
      !_vm.hasStart
        ? _c("tabs-header", {
            attrs: {
              tabs: _vm.tabs,
              active: _vm.tab,
              "set-active": _vm.setTab,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasStart && _vm.round === 1
        ? _c("div", { staticClass: "settings-container text-white" }, [
            _c(
              "div",
              {
                staticClass: "setting-button",
                on: { click: _vm.toggleSetting },
              },
              [_c("span", { staticClass: "glyphicon glyphicon-cog" })]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.settingsActive,
                    expression: "settingsActive",
                  },
                ],
                staticClass: "setting-modal",
              },
              [
                _c("h4", [_vm._v("回合數")]),
                _vm._v(" "),
                _c("div", { staticClass: "input-group input-group--list" }, [
                  _c("label", { attrs: { for: "rounds-one" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.rounds,
                          expression: "rounds",
                        },
                      ],
                      attrs: { id: "rounds-one", type: "radio", value: "1" },
                      domProps: { checked: _vm._q(_vm.rounds, "1") },
                      on: {
                        change: function ($event) {
                          _vm.rounds = "1"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("1")]),
                  ]),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "rounds-three" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.rounds,
                          expression: "rounds",
                        },
                      ],
                      attrs: { id: "rounds-three", type: "radio", value: "3" },
                      domProps: { checked: _vm._q(_vm.rounds, "3") },
                      on: {
                        change: function ($event) {
                          _vm.rounds = "3"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("3")]),
                  ]),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "rounds-five" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.rounds,
                          expression: "rounds",
                        },
                      ],
                      attrs: { id: "rounds-five", type: "radio", value: "5" },
                      domProps: { checked: _vm._q(_vm.rounds, "5") },
                      on: {
                        change: function ($event) {
                          _vm.rounds = "5"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("5")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("h4", [_vm._v("獲勝分數")]),
                _vm._v(" "),
                _c("div", { staticClass: "input-group input-group--list" }, [
                  _c("label", { attrs: { for: "match-score-eleven" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.matchScore,
                          expression: "matchScore",
                        },
                      ],
                      attrs: {
                        id: "match-score-eleven",
                        type: "radio",
                        value: "11",
                      },
                      domProps: { checked: _vm._q(_vm.matchScore, "11") },
                      on: {
                        change: function ($event) {
                          _vm.matchScore = "11"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(" 搶 11")]),
                  ]),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "match-score-twentyone" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.matchScore,
                          expression: "matchScore",
                        },
                      ],
                      attrs: {
                        id: "match-score-twentyone",
                        type: "radio",
                        value: "21",
                      },
                      domProps: { checked: _vm._q(_vm.matchScore, "21") },
                      on: {
                        change: function ($event) {
                          _vm.matchScore = "21"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(" 21 （ deuce ）")]),
                  ]),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "match-score-twentyfive" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.matchScore,
                          expression: "matchScore",
                        },
                      ],
                      attrs: {
                        id: "match-score-twentyfive",
                        type: "radio",
                        value: "25",
                      },
                      domProps: { checked: _vm._q(_vm.matchScore, "25") },
                      on: {
                        change: function ($event) {
                          _vm.matchScore = "25"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(" 搶 25")]),
                  ]),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "match-score-thirty" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.matchScore,
                          expression: "matchScore",
                        },
                      ],
                      attrs: {
                        id: "match-score-thirty",
                        type: "radio",
                        value: "30",
                      },
                      domProps: { checked: _vm._q(_vm.matchScore, "30") },
                      on: {
                        change: function ($event) {
                          _vm.matchScore = "30"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(" 搶 30")]),
                  ]),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "match-score-thirtyone" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.matchScore,
                          expression: "matchScore",
                        },
                      ],
                      attrs: {
                        id: "match-score-thirtyone",
                        type: "radio",
                        value: "31",
                      },
                      domProps: { checked: _vm._q(_vm.matchScore, "31") },
                      on: {
                        change: function ($event) {
                          _vm.matchScore = "31"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("搶 31")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("h4", [_vm._v("起始分數")]),
                _vm._v(" "),
                _c("div", { staticClass: "input-group" }, [
                  _c("span", { staticClass: "input-group-addon" }, [
                    _vm._v("藍隊："),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.blueStartScore,
                        expression: "blueStartScore",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number" },
                    domProps: { value: _vm.blueStartScore },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.blueStartScore = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-group" }, [
                  _c("span", { staticClass: "input-group-addon" }, [
                    _vm._v("紅隊："),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.redStartScore,
                        expression: "redStartScore",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number" },
                    domProps: { value: _vm.redStartScore },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.redStartScore = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: { "disabled-part": _vm.settingsActive } },
        [
          _c("scorer-tool", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab === "tool",
                expression: "tab === 'tool'",
              },
            ],
            attrs: {
              hasStart: _vm.hasStart,
              race: _vm.race,
              team: _vm.team,
              rounds: _vm.rounds,
              "match-score": _vm.matchScore,
              "blue-start-score": _vm.blueStartScore,
              "red-start-score": _vm.redStartScore,
              onClick: _vm.onOpen,
              onChange: _vm.onChange,
              onSubmit: _vm.onSubmit,
              setHasStart: _vm.setHasStart,
            },
          }),
          _vm._v(" "),
          _c("scorer-direct", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tab === "direct",
                expression: "tab === 'direct'",
              },
            ],
            attrs: {
              race: _vm.race,
              team: _vm.team,
              rounds: _vm.rounds,
              "match-score": +_vm.matchScore,
              "blue-start-score": +_vm.blueStartScore,
              "red-start-score": +_vm.redStartScore,
              redScore: _vm.getValue(
                _vm.redScore,
                _vm.redStartScore,
                _vm.matchScore
              ),
              blueScore: _vm.getValue(
                _vm.blueScore,
                _vm.blueStartScore,
                _vm.matchScore
              ),
              onClick: _vm.onOpen,
              onChange: _vm.onChange,
              onSubmit: _vm.onSubmit,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasStart,
              expression: "!hasStart",
            },
          ],
          staticClass: "buttons",
        },
        [
          _c("confirm-button", {
            attrs: {
              "custome-class": "btn-danger bg-red btn-lg",
              icon: "remove",
              "btn-text": "取消比賽",
              title: "確定取消比賽嗎？",
              type: "warning",
              "confirm-text": "是，刪除這場比賽",
              "confirm-url": `/races/${_vm.race.id}/cancel`,
            },
          }),
          _vm._v(" "),
          _c("confirm-button", {
            attrs: {
              "custome-class": "btn-warning bg-orange btn-lg",
              icon: "forward",
              "btn-text": "略過記錄",
              title: "確定略過記錄不計比分嗎？",
              type: "warning",
              "confirm-text": "是，略過這場記錄",
              "confirm-url": `/races/${_vm.race.id}/bypass`,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.group && _vm.round == 1
        ? _c("player-selector", {
            attrs: {
              fixed: true,
              active: !!_vm.selecting,
              players: _vm.players,
              selected: _vm.selectedIds,
              onClick: _vm.onClick,
              onClose: _vm.onClose,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "form",
          staticClass: "hide",
          attrs: { action: `/races/${_vm.race.id}/finish`, method: "post" },
        },
        [
          _c("input", {
            attrs: { name: "authenticity_token" },
            domProps: { value: _vm.token },
          }),
          _vm._v(" "),
          _vm.team.red1
            ? _c("input", {
                attrs: { name: "record[red1_id]" },
                domProps: { value: _vm.team.red1.id },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.team.red2
            ? _c("input", {
                attrs: { name: "record[red2_id]" },
                domProps: { value: _vm.team.red2.id },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.team.blue2 && _vm.team.blue1
            ? _c("input", {
                attrs: { name: "record[blue1_id]" },
                domProps: { value: _vm.team.blue1.id },
              })
            : _vm.team.blue1
            ? _c("input", {
                attrs: { name: "record[blue2_id]" },
                domProps: { value: _vm.team.blue1.id },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.team.blue2
            ? _c("input", {
                attrs: { name: "record[blue1_id]" },
                domProps: { value: _vm.team.blue2.id },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: { name: "record[red_score]" },
            domProps: { value: _vm.redScore || 0 },
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { name: "record[blue_score]" },
            domProps: { value: _vm.blueScore || 0 },
          }),
          _vm._v(" "),
          _vm.tab === "tool"
            ? [
                _vm.detail
                  ? _c("input", {
                      attrs: { name: "record[detail]" },
                      domProps: { value: _vm.detail },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }