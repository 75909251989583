var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-6" },
      [
        _c("label", { attrs: { for: "inputPlayer1" } }, [_vm._v("選手 1")]),
        _vm._v(" "),
        _c("player-select", {
          attrs: {
            options: _vm.options1,
            defaultValue: _vm.id1,
            onChange: _vm.onChange,
            name: "player1",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-6" },
      [
        _c("label", { attrs: { for: "inputLoser2" } }, [_vm._v("選手 2")]),
        _vm._v(" "),
        _c("player-select", {
          attrs: {
            options: _vm.options2,
            defaultValue: _vm.id2,
            onChange: _vm.onChange,
            name: "player2",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }