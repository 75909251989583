<template>
  <div class="selector" :class="{ fixed, active }">
    <div class="header">
      <div v-if="fixed" class="button" @click="onClose">
        <span class="glyphicon glyphicon-remove"></span>
      </div>

      <div class="space grow"></div>

      <div
        v-for="{ type, icon } in orders"
        :key="type"
        class="button"
        :class="{ active: order === type }"
        @click="() => setOrder(type)"
      >
        <span :class="`glyphicon glyphicon-${icon}`"></span>
      </div>

      <span class="space"></span>

      <div
        v-for="{ type, icon } in layouts"
        :key="type"
        class="button"
        :class="{ active: layout === type }"
        @click="() => setLayout(type)"
      >
        <span :class="`glyphicon glyphicon-${icon}`"></span>
      </div>
    </div>

    <div class="selector-main" :class="`${layout}`">
      <player-button
        v-for="p in playerList"
        :key="p.id"
        :player="p"
        :layout="layout"
        :onClick="onClick"
      />
    </div>
  </div>
</template>

<script>
import PlayerButton from './PlayerButton';

const orderBy = {
  score: (a, b) => b.point - a.point,
  time: (a, b) => new Date(b.last_active_at || undefined) - new Date(a.last_active_at || undefined),
};

export default {
  props: ["active", "fixed", "players", "selected", "onClick", "onClose"],
  components: { PlayerButton },

  unmounted() {
    document.body.classList.remove("operating");
  },

  data() {
    return {
      layouts: [
        { type: 'grid', icon: 'th-large' },
        { type: 'list', icon: 'list' },
      ],
      orders: [
        { type: 'time', icon: 'time' },
        { type: 'score', icon: 'object-align-bottom' },
      ],
      layout: 'list',
      order: 'score',
    };
  },

  methods: {
    setOrder(order) {
      this.order = order;
    },
    setLayout(layout) {
      this.layout = layout;
    },
  },

  computed: {
    selectedIds() {
      return this.selected || [];
    },

    playerList() {
      const { players, order, selectedIds } = this;
      return (players || [])
        .sort(orderBy[order])
        .map((p) => ({ ...p, selected: selectedIds.includes(p.id) }));
    },
  },

  watch: {
    active(nextActive) {
      const { fixed } = this;
      if (!fixed) return;

      if (nextActive) {
        document.body.classList.add("operating");
      } else {
        document.body.classList.remove("operating");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../_colors.scss';

.selector {
  display: flex;
  max-width: 800px;
  margin: auto;
  flex-direction: column;
  color: $white;
  overflow: hidden;
  transform: translateZ(0); // safari hack
  transition: opacity 0.3s;

  &.fixed {
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    z-index: 30;
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    will-change: top;

    @media (min-width: 768px) {
      top: 60px;
      bottom: 50px;
    }

    &.active {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

.header {
  position: sticky;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  flex-shrink: 0;
  background: $gray-dark;

  .space {
    display: block;
    width: 20px;

    &.grow {
      flex-grow: 1;
    }
  }

  .button {
    min-width: 50px;
    color: $white;
    line-height: 55px;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s;

    &:hover,
    &.active {
      background: $black;
    }

    .glyphicon {
      width: 1em;
    }
  }
}

.selector-main {
  flex-grow: 1;
  display: grid;
  padding: 10px;
  grid-gap: 10px;
  align-content: start;
  background: $gray-lighter;
  overflow: scroll;

  &.grid {
    grid-template-columns: repeat(3, 1fr);

    @media (min-width: 500px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 600px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 700px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &.list {
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: 550px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
