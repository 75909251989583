<template>
  <div class="input-group">
    <div class="input-group-addon">原始<span class="hidden-xs">積分</span>
      <span id="player-old-point">{{ old_point }}</span>
    </div>
    <input type="number" name="adjust_point" id="adjust_point" v-model="adjust_point" @change="update_point" class="form-control" />
    <div class="input-group-addon">調整後<span class="hidden-xs">積分</span>
      <span id="player-new-point">{{ new_point }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props:["point"],
  data() {
    return {
      old_point: this.point,
      adjust_point: 0,
      new_point: this.point,
    }
  },
  methods: {
    update_point() {
      this.new_point = parseInt(this.old_point, 10) + parseInt(this.adjust_point);
    }
  },
};
</script>
