var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.fields, function (field) {
        return _c("tr", { key: field.id }, [
          _c("td", [_vm._v(_vm._s(field.name))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(field.is_active))]),
          _vm._v(" "),
          _c("td", [
            field.race
              ? _c(
                  "div",
                  [
                    _vm._v("\n          紅隊："),
                    _vm._l(field.race.red_players, function (player) {
                      return _c("span", { key: player.id }, [
                        _vm._v(_vm._s(player.name)),
                      ])
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n          藍隊："),
                    _vm._l(field.race.blue_players, function (player) {
                      return _c("span", { key: player.id }, [
                        _vm._v(_vm._s(player.name)),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("td", [
            field.race
              ? _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.stateText(field.race.state)) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("名稱")]),
        _vm._v(" "),
        _c("th", [_vm._v("可使用")]),
        _vm._v(" "),
        _c("th", [_vm._v("賽事內容")]),
        _vm._v(" "),
        _c("th", [_vm._v("賽事狀態")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }