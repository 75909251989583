var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "record" }, [
    !_vm.pure
      ? _c("div", { staticClass: "record-head space-between" }, [
          _c("div", [
            _vm.last_step_url
              ? _c("a", { attrs: { href: _vm.last_step_url } }, [
                  _c("span", {
                    staticClass: "glyphicon glyphicon-backward text-blue",
                  }),
                  _vm._v(" 回到上一步\n      "),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div"),
          _vm._v(" "),
          _c("div", [
            _vm.next_step_url
              ? _c("a", { attrs: { href: _vm.next_step_url } }, [
                  _c("span", {
                    staticClass: "glyphicon glyphicon-play text-green",
                  }),
                  _vm._v(" 細部配對\n      "),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "record-card" }, [
      _c(
        "div",
        { staticClass: "record-team" },
        _vm._l(_vm.mate_players, function (item) {
          return _c(
            "a",
            {
              key: item.id,
              class: _vm.getPlayerClass(item.id, true),
              attrs: { href: _vm.playerUrl(item) },
            },
            [
              _c("img", { attrs: { src: item.avatar_url } }),
              _vm._v(" "),
              _c("div", { staticClass: "info full" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "record-score" }, [
        _c("div", { staticClass: "score" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.mate_wins_count) } }),
          _vm._v(" "),
          _c("div", { staticClass: "point hidden-xs" }, [
            _vm._v("( " + _vm._s(_vm.matePoint) + " )"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("-")]),
        _vm._v(" "),
        _c("div", { staticClass: "score" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.mate_loses_count) } }),
          _vm._v(" "),
          _c("div", { staticClass: "point hidden-xs" }, [
            _vm._v("( " + _vm._s(_vm.enemyPoint) + " )"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "record-team" },
        [
          _vm._l(_vm.enemy_players, function (item) {
            return _c(
              "a",
              {
                key: item.id,
                class: _vm.getPlayerClass(item.id, false),
                attrs: { href: _vm.playerUrl(item) },
              },
              [
                _c("img", { attrs: { src: item.avatar_url } }),
                _vm._v(" "),
                _c("div", { staticClass: "info full" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _vm._l(2 - _vm.enemy_players.length, function (n) {
            return _c("a", { key: n, class: _vm.getPlayerClass(0, false) }, [
              _c("img", { attrs: { src: _vm.default_avatar_url } }),
              _vm._v(" "),
              _c("div", { staticClass: "info full" }),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }