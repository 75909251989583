<template>
  <select class="form-control" :value="value" @change="onSelectChange">
    <option
      v-for="{ value, label, disabled } in formattedOptions"
      :key="value"
      :value="value"
      :disabled="disabled"
    >
      {{ label }}
    </option>
  </select>
</template>

<script>
export default {
  props: ["options", "defaultValue", "onChange", "name"],
  data: () => ({
    value: undefined,
  }),
  mounted() {
    const { defaultValue, formattedOptions } = this;
    this.value = defaultValue === undefined ? formattedOptions[0] : defaultValue;
  },
  methods: {
    onSelectChange({ target: { value } }) {
      this.value = value;

      const { name, onChange } = this;
      if (onChange) onChange(value, name);
    },
  },
  computed: {
    formattedOptions() {
      return this.options.map(o => (
        typeof o === "object" ? o : { value: o, label: o })
      );
    },
  },
};
</script>
