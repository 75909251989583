<template>
  <div>
    <select class="form-control" v-model="level" @change="onChange">
      <option :value="0">系統判定</option>
      <option disabled>------------------</option>
      <option v-for="(l, i) in levels" :key="i" :value="i + 1">{{ l }}</option>
    </select>
    <input
      class="form-control"
      :class="{ editable: false }"
      type="number"
      name="points[]"
      v-model="point"
    />
  </div>
</template>

<script>
const levels = ['菜鳥', '新手', '初階', '初上', '中下', '中階', '中上', '高手'];

export default {
  props:["mode"],
  data () {
    const default_level = 0;
    const default_point = 0;
    return {
      levels,
      level: default_level,
      point: default_point,
    }
  },

  methods: {
    onChange({ target: { value }}) {
      const level = parseInt(value, 10);
      if (this.mode == "normal") {
        this.point = level * 100;
      } else {
        this.point = level * 100 + 1400;
      }
    },
  },
};
</script>

<style scoped lang="scss">
div {
  width: 120px;
}

select {
  margin: 0 0 5px;
}

input {
  color: #fff;
  border-color: transparent;
  background: #979797;
  pointer-events: none;

  &.editable {
    color: #444;
    background: #fff;
    pointer-events: auto;
  }
}
</style>
