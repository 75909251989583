<template>
  <div class="record">
    <div class="record-mask">
      <a v-if="isJudge && isKickboxing == false" class="btn btn-lg btn-warning bg-orange" :href="scorerUrl">進行中</a>
      <div v-if="isJudge == false" class="btn btn-lg btn-warning bg-orange">
        <span>進行中</span><br>
        <span>裁判：{{ judgeName }}</span>
      </div>
    </div>

    <div class="record-card">
      <div class="record-team">
        <div
          v-for="item in bluePlayers"
          :key="item.id"
          v-bind:class="getPlayerClass(item, true)"
        >
          <img :src="item.avatar_url" />
          <div class="info full">{{ item.name }}</div>
        </div>
        <div
          v-if="bluePlayers.length == 0"
          v-bind:class="getPlayerClass(0, true)"
        >
          <img :src="unknownAvatarUrl" />
          <div class="info full">???</div>
        </div>
      </div>

      <div class="record-score">
        <div class="score">
          <span>?</span>
          <div class="point hidden-xs">( {{ bluePoint }} )</div>
        </div>
        <div> - </div>
        <div class="score">
          <span>?</span>
          <div class="point hidden-xs">( {{ redPoint }} )</div>
        </div>
      </div>

      <div class="record-team">
        <div
          v-for="item in redPlayers"
          :key="item.id"
          v-bind:class="getPlayerClass(item, false)"
        >
          <img :src="item.avatar_url" />
          <div class="info full">{{ item.name }}</div>
        </div>
        <div
          v-if="redPlayers.length == 0"
          v-bind:class="getPlayerClass(0, false)"
        >
          <img :src="unknownAvatarUrl" />
          <div class="info full">???</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: [
    'race_id',
    'refresh'
  ],

  data: () => ({
    bluePlayers: [],
    redPlayers:  [],
    unknownAvatarUrl: "",
    state: "",
    isJudge: false,
    isKickboxing: false,
    judgeName: "",
    scorerUrl: ""
  }),

  mounted() {
    this.updateData();
    this.scorerUrl = "/races/" + this.race_id + "/scorer"
    if (this.refresh === '1') {
      setInterval(() => {
        this.updateData();
      }, 5000);
    }
  },

  computed: {
    bluePoint() {
      return this.bluePlayers.length == 0
        ? "-"
        : this.teamPoint(this.bluePlayers);
    },
    redPoint() {
      return this.redPlayers.length == 0
        ? "-"
        : this.teamPoint(this.redPlayers);
    },
  },

  methods: {
    updateData() {
      const { race_id } = this;
      return axios
        .get("/in/races/queued_data/" + race_id)
        .then(({ data: { data } }) => {
          this.bluePlayers = data.blue_players
          this.redPlayers = data.red_players
          this.unknownAvatarUrl = data.unknown_avatar_url
          this.state = data.state
          this.isJudge = data.is_judge
          this.isKickboxing = data.is_kickboxing
          this.judgeName = data.judge_name
        })
        .finally(() => {});
    },

    getPlayerClass(player, is_blue) {
      let classes = "player player-label right"
      if (player.is_me) {
        classes += " me";
      }
      if (is_blue) {
        classes += " bg-blue";
      } else {
        classes += " bg-red";
      }
      return classes;
    },

    teamPoint(players) {
      if (players.filter(p => p.is_formal == false).length > 0) {
        return "-";
      }
      return Math.floor(
        players.reduce((acc, item) => acc + item.point, 0) / players.length
      );
    },
  },
};
</script>
