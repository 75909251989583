var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-group flex-center" },
    _vm._l(_vm.actives, function ({ type, text, icon, customClass, data }) {
      return _c("ajax-button", {
        key: type,
        attrs: {
          text: text,
          url: _vm.url,
          icon: icon,
          params: data,
          customClass: customClass,
          onFinish: _vm.onFinish,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }