<template>
  <table class="table">
    <thead class="bg-gray-light">
      <tr>
        <th>藍隊</th>
        <th>紅隊</th>
        <th>目前狀態</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="race in raceList" :key="race.id">
        <td>
          <div class="td-content">
            <span v-for="player in race.blue_players" :key="player.id">{{ player.name }}</span>
            <ajax-button
              v-for="{ u, f, t } in [{ u: 'join', f: 'canBlueJoin', t: '加入' }, { u: 'leave', f: 'canBlueLeave', t: '退出' }]"
              :key="f"
              v-show="race[f]"
              :url="`${race.url}${u}`"
              :data="{ side: 'blue' }"
              :onFinish="onFinish"
              :text="t"
              method="post"
              customClass="btn btn-primary bg-blue"
            ></ajax-button>
          </div>
        </td>
        <td>
          <div class="td-content">
            <span v-for="player in race.red_players" :key="player.id">{{ player.name }}</span>
            <ajax-button
              v-for="{ u, f, t } in [{ u: 'join', f: 'canRedJoin', t: '加入' }, { u: 'leave', f: 'canRedLeave', t: '退出' }]"
              :key="f"
              v-show="race[f]"
              :url="`${race.url}${u}`"
              :data="{ side: 'red' }"
              :onFinish="onFinish"
              :text="t"
              method="post"
              customClass="btn btn-danger bg-red"
            ></ajax-button>
          </div>
        </td>
        <td>
          <div class="td-content">
            <span>{{ stateText(race.state) }}</span>

            <ajax-button
              v-for="{ u, f, t } in [{ u: 'queue', f: 'canQueue', t: '確認' }, { u: 'bypass', f: 'canByPass', t: '略過' }]"
              :key="f"
              v-show="race[f]"
              :url="`${race.url}${u}`"
              :onFinish="onFinish"
              :text="t"
              method="post"
              customClass="btn btn-default"
            ></ajax-button>

            <a
              v-if="race.canScore"
              class="btn btn-warning bg-orange"
              :href="`/races/${race.id}/scorer`"
            >
              進入計分器
            </a>
          </div>
        </td>
      </tr>
      <tr>
        <td
          v-for="{ c, t } in [{ c: 'blue', t: 'primary' }, { c: 'red', t: 'danger' }]"
          :key="c"
        >
          <ajax-button
            :url="urlJoinNew"
            :data="{ side: c }"
            :onFinish="onFinish"
            method="post"
            text="加入"
            :customClass="`btn btn-${t} bg-${c} margin-top-2`"
          ></ajax-button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios';
import AjaxButton from '../Button/AjaxButton';

export default {
  props: ['schedule_id', 'user_player_id'],

  components: { AjaxButton },

  data: () => ({
    races: []
  }),

  mounted() {
    const { schedule_id } = this;

    this.updateData();
    this.$cable.subscribe({
      channel: 'ScheduleUnitsStatusChannel',
      schedule_id: schedule_id
    });
  },

  channels: {
    ScheduleUnitsStatusChannel: {
      connected() {},
      received(data) {
        this.races = data.data.races;
      }
    }
  },

  computed: {
    urlJoinNew() {
      const { schedule_id } = this;
      return `/in/schedules/${schedule_id}/races`;
    },

    raceList() {
      const { races, canJoin, canLeave } = this;

      return races
        .map((race) => ({
          ...race,
          canRedJoin: canJoin(race, 'red'),
          canBlueJoin: canJoin(race, 'blue'),
          canRedLeave: canLeave(race, 'red'),
          canBlueLeave: canLeave(race, 'blue'),
          canQueue: race.state == 'pending',
          canScore: race.state == 'queued' || race.state == 'in_field' || race.state == 'processing',
          canBypass: race.state == 'queued' || race.state == 'in_field' || race.state == 'processing',
          url: `/in/races/${race.id}/`,
        }));
    },
  },

  methods: {
    updateData() {
      const { schedule_id } = this;
      return axios
        .get("/in/schedules/" + schedule_id + "/races")
        .then(({ data: { data } }) => {
          this.races = data.races;
        });
    },

    containsMe(list) {
      const { user_player_id } = this;
      return list.filter(p => p.id == user_player_id).length > 0;
    },

    canJoin(race, team) {
      const { containsMe } = this;

      if(containsMe(race.red_players)) return false;
      if(containsMe(race.blue_players)) return false;

      const teamFieldName = `${team}_players`;
      if(race[teamFieldName].length >= 2) return false;

      return race.state == 'pending';
    },

    canLeave(race, team) {
      const teamFieldName = `${team}_players`;
      return race.state == 'pending' && this.containsMe(race[teamFieldName]);
    },

    onFinish({ data: { status, message, data } }) {
      if (status === 'success') {
        this.races = data.races;
        return;
      }

      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'error',
        title: message
      });
    },

    stateText(state) {
      if (state == 'pending') return '組隊中';
      if (state == 'queued') return '排隊中';
      if (state == 'in_field') return '進入球場';
      if (state == 'processing') return '比賽進行中';
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  max-width: 700px;
  margin: 20px auto;
}

.td-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;

    > * {
      margin: 0 2px;
    }
  }
}

* + .btn {
  margin: 5px 0 0;

  @media (min-width: 768px) {
    margin: 0 0 0 5px;
  }
}

tr {
  & + & {
    border-top: 1px solid;
  }
}

th, td {
  border: 0 !important;
}
</style>
