<template>
  <div>
    <button class="btn btn-default" @click="onMinus">-</button>
    <span>{{ crowd }}</span>
    <button class="btn btn-default" @click="onAdd">+</button>
  </div>
</template>

<script>
export default {
  props: ['id', 'crowd', 'onChange'],

  methods: {
    onMinus () {
      const next = Math.max(1, this.crowd - 1);
      this.onCrowdChange(next);
    },

    onAdd () {
      const next = Math.max(1, this.crowd + 1);
      this.onCrowdChange(next);
    },

    onCrowdChange(nextCrowd) {
      const { id, onChange } = this;
      onChange(id, { crowd: nextCrowd });
    },
  }
};
</script>

<style scoped lang="scss">
span {
  display: inline-block;
  width: 30px;
}
</style>
