var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { on: { click: _vm.change } }, [
    _c("img", { class: `${_vm.imgClass}`, attrs: { alt: "", src: _vm.src } }),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: "genders[]" },
      domProps: { value: _vm.gender },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }