<template>
  <div class="tab-pane active">
    <h4 v-if="!hasStart" class="text-center">設定選手、位置、發球方</h4>

    <div class="scorer-box">
      <div>
        <div v-if="!hasStart" class="text-center buttons">
          <button class="btn bg-yellow text-gray" @click="changeServe">
            <span class="glyphicon glyphicon-flash"></span>
            更換發球方
            <span class="glyphicon glyphicon-flash"></span>
          </button>
        </div>

        <field
          :team="team"
          :firstServing="firstServing"
          :history="history"
          :redStartScore="redStartScore"
          :blueStartScore="blueStartScore"
          :started="hasStart"
          :onClick="hasStart ? undefined : onClick"
        />

        <template v-if="!hasStart">
          <div class="text-center buttons" :class="{ reverse: needSwitch }">
            <button
              class="btn btn-primary bg-blue"
              :class="{ hide: !isDouble }"
              @click="() => changeSide('blue')"
            >
              <span class="glyphicon glyphicon-sort"></span> 站位對調
            </button>
            <button
              class="btn btn-danger bg-red"
              :class="{ hide: !isDouble }"
              @click="() => changeSide('red')"
            >
              <span class="glyphicon glyphicon-sort"></span> 站位對調
            </button>
          </div>
          <div class="text-center buttons">
            <button class="btn btn-warning bg-orange" @click="changeField">
              <span class="glyphicon glyphicon-retweet"></span> 互換場地
            </button>
          </div>
        </template>
      </div>

      <div>
        <button
          v-if="!hasStart"
          class="btn btn-success bg-green btn-lg btn-block"
          @click="start"
        >
          <span class="glyphicon glyphicon-fire"></span>
          設定完成，開始比賽
          <span class="glyphicon glyphicon-fire"></span>
        </button>
        <template v-else>
          <div class="text-center buttons">
            <button class="btn btn-warning bg-orange" @click="changeField">
              <span class="glyphicon glyphicon-retweet"></span> 互換場地
            </button>
          </div>

          <div class="score-board" :class="{ reverse: needSwitch }">
            <div class="score-card bg-blue" @click="() => addPoint('blue')">
              {{ getScores.blue }}
            </div>
            <div class="score-card bg-red" @click="() => addPoint('red')">
              {{ getScores.red }}
            </div>
          </div>

          <p class="text-center action">
            <button class="btn btn-warning bg-orange" @click="reset">
              <span class="glyphicon glyphicon-repeat"></span> 重設
            </button>
            <button
              v-if="history.length"
              class="btn btn-warning bg-orange"
              @click="revert"
            >
              <span class="glyphicon glyphicon-backward"></span> 退回上一步
            </button>
          </p>
        </template>
      </div>
    </div>

    <p v-if="canSubmit" class="text-center">
      <button class="btn btn-lg btn-success bg-green" @click="clearAndSubmit">
        送出對戰成績
      </button>
    </p>
  </div>
</template>

<script>
import axios from 'axios';
import Field from './Field';

const meta = document.querySelector('meta[name=csrf-token]');
const token = meta && meta.content;
axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

export default {
  props: ['race', 'team', 'rounds', 'matchScore', 'blueStartScore', 'redStartScore', 'onClick', 'onChange', 'onSubmit', 'hasStart', 'setHasStart'],
  components: { Field },

  data() {
    return {
      firstServing: 'blue',
      history: [],
    };
  },

  mounted() {
    const storageLogs = this.getStorageLogs();

    if (!storageLogs || !storageLogs.history || !storageLogs.history.length) return;

    const { firstServing, history } = storageLogs;

    this.$swal({
      title: "是否復原紀錄",
      text: "檢測到有未完成的比賽紀錄，是否要復原計分器呢？",
      icon: "question",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: "不要！讓一切重來吧！",
      confirmButtonText: "好啊～",
    }).then(({ value, dismiss }) => {
      if (!value) return;

      this.changeSide('blue');
      this.firstServing = firstServing || 'blue';
      this.setHasStart(true);
      this.history = history;
    });
  },

  channels: {
    LiveChannel: {
      connected() {
      }
    }
  },

  computed: {
    isDouble() {
      const { team } = this;
      return team.red1 && team.red2 && team.blue1 && team.blue2;
    },

    canSubmit() {
      const { history, matchScore } = this;

      const s = history.filter((h) => h.type === 'score');
      const red = s.filter(({ team }) => team === 'red').length + parseInt(this.redStartScore, 10);
      const blue = s.filter(({ team }) => team === 'blue').length + parseInt(this.blueStartScore, 10);

      const max = Math.max(red, blue);
      const min = Math.min(red, blue);

      if (matchScore == 21) {
        return max >= 31 || (max >= 21 && max - min >= 2);
      } else {
        return max == matchScore;
      }
    },

    needSwitch() {
      return this.history.filter((h) => h.type === 'field').length % 2 === 1;
    },

    getScores() {
      const { history } = this;
      const s = history.filter((h) => h.type === 'score');
      return {
        red: s.filter(({ team }) => team === 'red').length + parseInt(this.redStartScore, 10),
        blue: s.filter(({ team }) => team === 'blue').length + parseInt(this.blueStartScore, 10),
      };
    },
  },

  methods: {
    getStorageLogs() {
      const { race: { id } } = this;

      const logs = JSON.parse(localStorage.getItem('logs'));
      if (!logs || typeof logs != 'object' || logs.constructor != Object) return;
      return (logs || {})[id];
    },

    updateStorageLogs() {
      const { race: { id }, firstServing, history, getStorageLogs } = this;

      const orgLogs = getStorageLogs();
      localStorage.setItem('logs', JSON.stringify({
        ...orgLogs,
        [id]: { firstServing, history }
      }));
    },

    clearAndSubmit() {
      localStorage.removeItem('logs');
      this.onSubmit();
    },

    changeField() {
      this.history = [...this.history, { type: 'field' }];
    },

    changeSide(team) {
      const { onChange } = this;

      const key1 = `${team}1`;
      const key2 = `${team}2`;
      const { [key1]: player1, [key2]: player2 } = this.team;

      onChange(key1, player2.id);
      onChange(key2, player1.id);
    },

    changeServe() {
      this.firstServing = this.firstServing === 'blue' ? 'red' : 'blue';
    },

    addPoint(team) {
      const { canSubmit } = this;

      if (canSubmit) return;
      this.history = [...this.history, { type: 'score', team }];
      this.scored();
    },

    start() {
      const { race, team, rounds, matchScore, blueStartScore, redStartScore, onChange } = this;

      const red = [team.red1, team.red2].filter((p) => p).length;
      const blue = [team.blue1, team.blue2].filter((p) => p).length;

      if (!red || red !== blue) {
        this.$swal({ icon: 'error', title: '選手數目不對' });
        return;
      }

      this.setHasStart(true);

      const singleRed = team.red1 || team.red2;
      const singleBlue = team.blue1 || team.blue2;

      if (red === 1) {
        onChange('red1', singleRed.id);
        onChange('red2', undefined);
        onChange('blue1', singleBlue.id);
        onChange('blue2', undefined);
      }

      const { id, schedule_id } = race;

      const formData = new FormData();
      formData.append('race_id', id);
      if (schedule_id) formData.append('schedule_id', schedule_id);

      if (red === 1) {
        formData.append('red[]', singleRed.id);
        formData.append('blue[]', singleBlue.id);
      } else {
        formData.append('red[]', team.red1.id);
        formData.append('red[]', team.red2.id);
        formData.append('blue[]', team.blue2.id);
        formData.append('blue[]', team.blue1.id);
      }
      formData.append('rounds', rounds);
      formData.append('match_score', matchScore);
      formData.append('blue_start_score', blueStartScore);
      formData.append('red_start_score', redStartScore);

      axios.post(`/in/races/start/${id}`, formData);
    },

    revert() {
      this.history.splice(-1, 1);
      this.scored();
    },

    reset() {
      this.$swal({
        reverseButtons: true,
        showCancelButton: true,
        icon: 'warning',
        title: '確定要重設嗎？',
        confirmButtonText: '是！把一切都重設吧！',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (!value) return;

        this.setHasStart(false);
        this.history = [];
        this.scored();
      });
    },

    async scored() {
      const { race, getScores: { red, blue } } = this;
      const { id } = race;
      await axios.put(`/in/races/${id}/score`, {
        red_score: red,
        blue_score: blue,
      });
    }
  },

  watch: {
    canSubmit(enable) {
      if (!enable) return;

      const {
        firstServing,
        history,
        getScores: { red, blue },
        onChange,
        onSubmit,
      } = this;

      const codes = { red: '1', blue: '0' };
      const records = history
        .filter(({ type }) => type == 'score')
        .map(({ team }) => codes[team]);

      onChange('redScore', red);
      onChange('blueScore', blue);
      onChange('detail', `${codes[firstServing]};${records.join('')}`);

      const [team, text, scoreText] =
        blue > red
          ? ['blue', '藍', `${blue}:${red}`]
          : ['red', '紅', `${red}:${blue}`];

      const winnerText = `<span class="text-${team}">${text}隊</span>`;

      this.$swal({
        reverseButtons: true,
        showCancelButton: true,

        icon: 'success',
        title: '比賽結束',
        html: `恭喜 ${winnerText} 以 ${scoreText} 獲得勝利！`,
        confirmButtonText: '立刻送出成績',
        cancelButtonText: '且慢，我再檢查一下',
      }).then(({ value }) => {
        if (!value) return;
        this.clearAndSubmit();
      });
    },

    firstServing() {
      this.updateStorageLogs();
    },

    history(h) {
      this.updateStorageLogs();
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../_colors.scss';

h4 {
  margin-top: 20px;
}

.scorer-box {
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  align-content: center;
  justify-content: center;
  transform: translateZ(0); // safari hack

  @media (min-width: 1024px) {
    margin: 0 0 0 70px;
    grid-gap: 20px;
    grid-template-columns: 4fr 3fr;
  }
}

.reverse {
  flex-direction: row-reverse;
}

.btn-block {
  max-width: 300px;
  margin: 0 auto 30px;
}

.buttons + .buttons {
  margin-bottom: 30px;
  padding-top: 0;
}
</style>
