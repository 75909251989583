var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row text-center" },
    [
      _c("div", { staticClass: "player-row" }, [
        _c("div", { staticClass: "td--sm" }, [_vm._v("選手")]),
        _vm._v(" "),
        _c("div", { staticClass: "td--sm" }, [_vm._v("狀態")]),
        _vm._v(" "),
        _c("div", { staticClass: "td--lg flex-center" }, [
          _vm._v("\n      編輯" + _vm._s(_vm.action.text) + "\n      "),
          _c(
            "button",
            {
              staticClass: "btn btn-link text-blue-light",
              on: { click: _vm.onToggle },
            },
            [_vm._v("切換")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(
        _vm.players,
        function ({
          player_id: id,
          name,
          crowd,
          state,
          available_events: events,
        }) {
          return _c("div", { key: id, staticClass: "player-row" }, [
            _c("div", { staticClass: "td--sm" }, [_vm._v(_vm._s(name))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "td--sm" },
              [_c("PlayerState", { attrs: { id: id, state: state } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "td--lg" },
              [
                _vm.action.id === "state"
                  ? _c("PlayerEvents", {
                      attrs: { id: id, events: events, onChange: _vm.onChange },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.action.id === "crowd"
                  ? _c("PlayerCrowds", {
                      attrs: { id: id, crowd: crowd, onChange: _vm.onChange },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }