<template>
  <li>
    <a :href="notification.link">
      <img v-if="notification.image" alt="" :src="notification.image" />
      <b v-else>{{ notification.word }}</b>
      <p>
        <span>{{ notification.message }}</span>
        <small>{{ notification.time_words }}</small>
      </p>
    </a>
  </li>
</template>

<script>
export default {
  props: ['notification'],
}
</script>

<style scoped lang="scss">
li {
  margin: 10px;
}

a {
  display: flex;
  padding: 15px;
  background: #767676;
  border-radius: 10px;

  &:hover {
    background: #767676;
  }
}

img,
b {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

b {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: 400;
  background: #424242;
  overflow: hidden;
}

p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 15px;
}

span {
  max-height: 2.6em;
  margin: 10px 0 5px;
  line-height: 1.3em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
