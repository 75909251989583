<template>
<div class="row margin-top-2">
  <div class="col-xs-6">
    <select class="form-control" v-model="staticYear">
      <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
    </select>
  </div>

  <div class="col-xs-6">
    <select class="form-control" v-model="groupId">
      <option v-for="{ id, name } in groups" :key="id" :value="id">{{ name }}</option>
    </select>
  </div>

  <div class="player_info">
    <div class="chart_container margin-top-3">
      <v-frappe-chart
        type="bar"
        :labels="labels"
        :data="datasets"
        :colors="['red']"
      />
    </div>
    <div class="chart_container margin-top-3" style="overflow:scroll;">
      <v-frappe-chart
        type="heatmap"
        :data="heatmapDatasets"
        :start="heatmapStart"
        :end="heatmapEnd"
        :height="200"
        :radius="1"
        :discreteDomains="1"
      />
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";

export default {
  props: ["years", "groups", "user_id"],

  data: () => ({
    staticYear: `${new Date().getFullYear()}`,
    groupId: "0",
    labels: new Array(12).fill(0).map((v, i) => `${i + 1}月`),
    datasets: [],
    heatmapDatasets: { },
    heatmapStart: new Date(new Date().getFullYear(), 1, 1),
    heatmapEnd: new Date(new Date().getFullYear(), 12, 31)
  }),

  mounted() {
    const { years, groups } = this;
    this.staticYear = `${years[0]}`;
    this.groupId = `${groups[0].id}`;
    this.updateData();
  },

  methods: {
    async updateData() {
      this.datasets = await this.getData();
      await this.getHeatmapData();
    },

    getData() {
      const { groupId, staticYear, user_id, formatData } = this;
      return axios
        .get("/in/users/result_static_chart_data", {
          params: { group_id: groupId, year: staticYear, user_id: user_id },
        })
        .then(({ data: { data } }) => formatData(data))
        .finally(() => {});
    },

    getHeatmapData() {
      const { groupId, staticYear, user_id, formatHeatmapData } = this;
      return axios
        .get("/in/users/result_static_heatmap_data", {
          params: { group_id: groupId, year: staticYear, user_id: user_id },
        })
        .then(({ data: { data } }) => formatHeatmapData(data))
        .finally(() => {});
    },

    formatData({ win_counts, lose_counts }) {
      return [
        { name: "敗北場次", chartType: 'bar', values: lose_counts },
        { name: "獲勝場次", chartType: 'bar', values: win_counts },
      ];
    },

    formatHeatmapData({ data_points, start, end }) {
      const { staticYear } = this;
      this.heatmapDatasets = data_points;
      this.heatmapStart = new Date(staticYear, 0, 1);
      this.heatmapEnd = new Date(staticYear, 11, 31);
    },
  },

  watch: {
    groupId(n, o) { if(n !== o) this.updateData(); },
    staticYear(n, o) { if(n !== o) this.updateData(); },
  },
};
</script>
