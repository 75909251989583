<template>
  <div
    class="player player-label"
    :class="{ [`text-${team}`]: !player, [`bg-${team}`]: !!player }"
    :style="customStyle"
    @click="onPlayerClick"
  >
    <template v-if="player">
      <img :alt="player.name" :src="avatar" />
      <div class="info full">{{ player.name }}</div>
      <span v-if="!started" class="glyphicon glyphicon-remove-sign" :class="{ [`text-${team}`]: true }" />
    </template>
  </div>
</template>

<script>
export default {
  props: ['team', 'order', 'player', 'started', 'customStyle', 'onClick'],
  computed: {
    avatar() {
      const { avatar } = this.player;
      if (avatar) return avatar;
      return '/assets/images/avatar-unknown.png';
    },
  },
  methods: {
    onPlayerClick() {
      const { team, order, onClick } = this;
      if (onClick) onClick(`${team}${order}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../_colors.scss';

.player {
  position: absolute;
  z-index: 2;
  height: 40px;
  margin: auto;
  cursor: pointer;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 0 3px) brightness(1);
  transition: top 0.3s, left 0.3s, right 0.3s, bottom 0.3s, filter 0.3s;
  will-change: top, left;

  @media (min-width: 360px) {
    transform: translate3d(0, 5px, 5px) rotate3d(1, 0, 0, -10deg);
  }

  &:hover {
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 0 3px) brightness(1.2);
  }

  &:empty {
    width: 60px;
    height: 60px;
    padding: 0;
    background: transparent;
    border-radius: 50%;

    &::before {
      content: "\e008";
      width: 60px;
      height: 60px;
      border: 3px solid;
      font-family: "Glyphicons Halflings";
      font-size: 30px;
      line-height: 1.7;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.glyphicon-remove-sign {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  font-size: 16px;
  background: #fff;
  border-radius: 50%;
}
</style>
