<template>
  <table class="table">
    <thead>
      <tr>
        <th>名稱</th>
        <th>可使用</th>
        <th>賽事內容</th>
        <th>賽事狀態</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="field in fields" :key="field.id">
        <td>{{ field.name }}</td>
        <td>{{ field.is_active }}</td>
        <td>
          <div v-if="field.race">
            紅隊：<span v-for="player in field.race.red_players" :key="player.id">{{ player.name }}</span>
            <br>
            藍隊：<span v-for="player in field.race.blue_players" :key="player.id">{{ player.name }}</span>
          </div>
        </td>
        <td>
          <div v-if="field.race">
            {{ stateText(field.race.state) }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios';

export default {
  props: [
    'schedule_id'
  ],

  data: () => ({
    fields: []
  }),

  mounted() {
    const { schedule_id } = this;

    this.updateData();
    this.$cable.subscribe({
      channel: 'ScheduleUnitsStatusChannel',
      schedule_id: schedule_id
    });
  },

  channels: {
    ScheduleUnitsStatusChannel: {
      connected() {},
      received(data) {
        this.fields = data.data.fields;
      }
    }
  },

  computed: {
  },

  methods: {
    updateData() {
      const { schedule_id } = this;
      return axios
        .get("/in/schedules/" + schedule_id + "/fields")
        .then(({ data: { data } }) => {
          this.fields = data.fields;
        })
        .finally(() => {});
    },
    stateText(state) {
      if (state == 'pending') return '組隊中';
      if (state == 'queued') return '排隊中';
      if (state == 'in_field') return '進入球場';
      if (state == 'processing') return '比賽進行中';
    }
  },
};
</script>
