var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-pane active" }, [
    !_vm.hasStart
      ? _c("h4", { staticClass: "text-center" }, [
          _vm._v("設定選手、位置、發球方"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "scorer-box" }, [
      _c(
        "div",
        [
          !_vm.hasStart
            ? _c("div", { staticClass: "text-center buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn bg-yellow text-gray",
                    on: { click: _vm.changeServe },
                  },
                  [
                    _c("span", { staticClass: "glyphicon glyphicon-flash" }),
                    _vm._v("\n          更換發球方\n          "),
                    _c("span", { staticClass: "glyphicon glyphicon-flash" }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("field", {
            attrs: {
              team: _vm.team,
              firstServing: _vm.firstServing,
              history: _vm.history,
              redStartScore: _vm.redStartScore,
              blueStartScore: _vm.blueStartScore,
              started: _vm.hasStart,
              onClick: _vm.hasStart ? undefined : _vm.onClick,
            },
          }),
          _vm._v(" "),
          !_vm.hasStart
            ? [
                _c(
                  "div",
                  {
                    staticClass: "text-center buttons",
                    class: { reverse: _vm.needSwitch },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary bg-blue",
                        class: { hide: !_vm.isDouble },
                        on: { click: () => _vm.changeSide("blue") },
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-sort" }),
                        _vm._v(" 站位對調\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger bg-red",
                        class: { hide: !_vm.isDouble },
                        on: { click: () => _vm.changeSide("red") },
                      },
                      [
                        _c("span", { staticClass: "glyphicon glyphicon-sort" }),
                        _vm._v(" 站位對調\n          "),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-center buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning bg-orange",
                      on: { click: _vm.changeField },
                    },
                    [
                      _c("span", {
                        staticClass: "glyphicon glyphicon-retweet",
                      }),
                      _vm._v(" 互換場地\n          "),
                    ]
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          !_vm.hasStart
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success bg-green btn-lg btn-block",
                  on: { click: _vm.start },
                },
                [
                  _c("span", { staticClass: "glyphicon glyphicon-fire" }),
                  _vm._v("\n        設定完成，開始比賽\n        "),
                  _c("span", { staticClass: "glyphicon glyphicon-fire" }),
                ]
              )
            : [
                _c("div", { staticClass: "text-center buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning bg-orange",
                      on: { click: _vm.changeField },
                    },
                    [
                      _c("span", {
                        staticClass: "glyphicon glyphicon-retweet",
                      }),
                      _vm._v(" 互換場地\n          "),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "score-board",
                    class: { reverse: _vm.needSwitch },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "score-card bg-blue",
                        on: { click: () => _vm.addPoint("blue") },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getScores.blue) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "score-card bg-red",
                        on: { click: () => _vm.addPoint("red") },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getScores.red) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "text-center action" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning bg-orange",
                      on: { click: _vm.reset },
                    },
                    [
                      _c("span", { staticClass: "glyphicon glyphicon-repeat" }),
                      _vm._v(" 重設\n          "),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.history.length
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-warning bg-orange",
                          on: { click: _vm.revert },
                        },
                        [
                          _c("span", {
                            staticClass: "glyphicon glyphicon-backward",
                          }),
                          _vm._v(" 退回上一步\n          "),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.canSubmit
      ? _c("p", { staticClass: "text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-lg btn-success bg-green",
              on: { click: _vm.clearAndSubmit },
            },
            [_vm._v("\n      送出對戰成績\n    ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }