<template>
  <div class="notifications" :class="{ active }">
    <div class="title" :class="{ highlight }">
      通知
      <button class="close-button" @click="toggle" />
    </div>

    <ul>
      <Li
        v-for="{ id, ...n } in list"
        :key="id"
        :notification="n"
      />
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import Li from "./Li";

export default {
  components: { Li },
  data() {
    return {
      active: false,
      highlight: false,
      list: [],
    };
  },
  mounted() {
    this.updateData();
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
    updateData() {
      axios.get("/in/notifications")
        .then(({ data: { data } }) => {
          this.list = data.notifications;
          this.highlight = data.highlight;
        });
    },
  },
  watch: {
    active(nextActive) {
      if (!nextActive) {
        document.body.classList.remove("operating");
        return;
      }

      document.body.classList.add("operating");
      if (!this.highlight) return;
      this.highlight = false;
      axios.post("/in/notifications/read");
    },
  },
}
</script>

<style scoped lang="scss">
.notifications {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background: rgba(50, 50, 50, 0.9);
  transform: translate(-100%, 0);
  transition: transform 0.5s;

  &.active {
    transform: translate(0, 0);
  }
}

.title {
  flex-shrink: 0;
  position: relative;
  display: block;
  border-bottom: 1px solid;
  padding: 20px 0;
  font-size: 24px;
  font-weight: 400;
  text-align: center;

  &.highlight::before {
    content: "";
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 40px;
    height: 40px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid #f7941c;
    transform: translate(93%, 245%);
    transition: opacity 0.3s 0.4s, transform 0.3s 0.3s;
  }

  .notifications.active &.highlight::before {
    opacity: 0;
    transform: translate(93%, 245%) scale(0.5);
    transition: opacity 0.3s, transform 0.3s;
  }
}

.close-button {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 40px;
  height: 40px;
  margin: auto 0;
  border: 0;
  padding: 0;
  background: transparent;
  transform: translate(95%, 200%);
  transition: transform 0.5s;
  cursor: pointer;

  .notifications.active & {
    transform: translate(0, 0);
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    border-radius: 10px;
    transition: transform 0.5s;
  }

  &::before { transform: translate(-50%, -320%) rotate(35deg) scale(0.8); }
  &::after { transform: translate(-50%, 320%) rotate(-35deg) scale(0.8); }

  .notifications.active &::before { transform: translate(-50%, -50%) rotate(45deg) scale(0.8); }
  .notifications.active &::after { transform: translate(-50%, -50%) rotate(-45deg) scale(0.8); }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}
</style>
