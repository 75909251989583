<template>
  <div class="container">
    <div v-if="step === 1">
      <p>請輸入選手們姓名（ 一行一個 ），全部輸入完成後點擊 <button class="btn btn-info bg-blue-light" @click="next">下一步</button>。</p>
      <p>
        <textarea
          class="form-control"
          id="names"
          placeholder="小羽（ 選手Ａ ）
屈肯（ 選手Ｂ ）
炸雞
烤雞
一隻雞"
          v-model="names"
        />
      </p>
      <p class="text-right">
        <button class="btn btn-info bg-blue-light" @click="next">下一步</button>
      </p>
    </div>

    <div v-if="step === 2">
      <table class="table" id="player_list">
        <thead>
          <th>姓名</th>
          <th width="65">性別</th>
          <th width="120">程度</th>
        </thead>
        <tbody>
          <tr v-for="(name, index) in nameArray" :key="index">
            <td><input name="names[]" :value="name" class="form-control" /></td>
            <td><gender-switch /></td>
            <td><level-select :mode="mode"/></td>
          </tr>
        </tbody>
      </table>
      <p class="text-center">
        <br />
        <br />
        <br />
        <button class="btn btn-warning bg-orange" @click="prev">上一步</button>
        &nbsp;
        <button type="submit" class="btn btn-success bg-green">確認</button>
      </p>
    </div>
  </div>
</template>

<script>
import GenderSwitch from "../Player/GenderSwitch";
import LevelSelect  from "../Player/LevelSelect";

export default {
  components: { GenderSwitch, LevelSelect },
  props: ["mode"],
  data () {
    return {
      step: 1,
      names: "",
    }
  },

  computed: {
    nameArray () {
      const { names } = this;
      if (!names) return [];

      return names
        .split(/[\n,\r]/)
        .map(name => name.replace(/\s/g, ''))
        .filter(n => n);
    },

    readyForNext () {
      const { nameArray } = this;
      return nameArray && nameArray.length;
    },
  },

  methods: {
    prev(event) {
      event.preventDefault();
      this.step = 1;
    },

    next(event) {
      event.preventDefault();

      const { nameArray, readyForNext } = this;
      if (!readyForNext) {
        this.$swal({ icon: 'error', title: '您未輸入任何選手名字' });
        return;
      }

      this.step = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
  textarea {
    resize: none;
    height: 200px;
    padding: 15px;
    line-height: 1.5;
  }
</style>
