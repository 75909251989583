<template>
  <div class="row">
    <div class="col-md-6">
      <label for="inputPlayer1">選手 1</label>
      <player-select
        :options="options1"
        :defaultValue="id1"
        :onChange="onChange"
        name="player1"
      />
    </div>
    <div class="col-md-6">
      <label for="inputLoser2">選手 2</label>
      <player-select
        :options="options2"
        :defaultValue="id2"
        :onChange="onChange"
        name="player2"
      />
    </div>
  </div>
</template>

<script>
import PlayerSelect from "./Select";

export default {
  components: { PlayerSelect },

  props: ["players", "id1", "id2", "urlPrefix"],

  methods: {
    onChange(v, name) {
      const { id1, id2, urlPrefix } = this;
      const { player1, player2 } = { player1: id1, player2: id2, [name]: v };
      window.location = `${urlPrefix}?player1_id=${player1}&player2_id=${player2}`;
    },
  },

  computed: {
    options() {
      const { players } = this;
      return (players || []).map(p => ({ value: `${p.id}`, label: p.name }));
    },

    options1() {
      const { options, id2 } = this;
      return options.map(o => (
        o.value === id2 ? { ...o, disabled: true } : o
      ));
    },

    options2() {
      const { options, id1 } = this;
      return options.map(o => (
        o.value === id1 ? { ...o, disabled: true } : o
      ));
    }
  },
};
</script>
