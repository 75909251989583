var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.step === 1
      ? _c("div", [
          _c("p", [
            _vm._v("請輸入選手們姓名（ 一行一個 ），全部輸入完成後點擊 "),
            _c(
              "button",
              {
                staticClass: "btn btn-info bg-blue-light",
                on: { click: _vm.next },
              },
              [_vm._v("下一步")]
            ),
            _vm._v("。"),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.names,
                  expression: "names",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "names",
                placeholder:
                  "小羽（ 選手Ａ ）\n屈肯（ 選手Ｂ ）\n炸雞\n烤雞\n一隻雞",
              },
              domProps: { value: _vm.names },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.names = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-info bg-blue-light",
                on: { click: _vm.next },
              },
              [_vm._v("下一步")]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.step === 2
      ? _c("div", [
          _c("table", { staticClass: "table", attrs: { id: "player_list" } }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.nameArray, function (name, index) {
                return _c("tr", { key: index }, [
                  _c("td", [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { name: "names[]" },
                      domProps: { value: name },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("td", [_c("gender-switch")], 1),
                  _vm._v(" "),
                  _c(
                    "td",
                    [_c("level-select", { attrs: { mode: _vm.mode } })],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-center" }, [
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-warning bg-orange",
                on: { click: _vm.prev },
              },
              [_vm._v("上一步")]
            ),
            _vm._v("\n         \n        "),
            _c(
              "button",
              {
                staticClass: "btn btn-success bg-green",
                attrs: { type: "submit" },
              },
              [_vm._v("確認")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("姓名")]),
      _vm._v(" "),
      _c("th", { attrs: { width: "65" } }, [_vm._v("性別")]),
      _vm._v(" "),
      _c("th", { attrs: { width: "120" } }, [_vm._v("程度")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }