var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      [
        _vm._l(_vm.raceList, function (race) {
          return _c("tr", { key: race.id }, [
            _c("td", [
              _c(
                "div",
                { staticClass: "td-content" },
                [
                  _vm._l(race.blue_players, function (player) {
                    return _c("span", { key: player.id }, [
                      _vm._v(_vm._s(player.name)),
                    ])
                  }),
                  _vm._v(" "),
                  _vm._l(
                    [
                      { u: "join", f: "canBlueJoin", t: "加入" },
                      { u: "leave", f: "canBlueLeave", t: "退出" },
                    ],
                    function ({ u, f, t }) {
                      return _c("ajax-button", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: race[f],
                            expression: "race[f]",
                          },
                        ],
                        key: f,
                        attrs: {
                          url: `${race.url}${u}`,
                          data: { side: "blue" },
                          onFinish: _vm.onFinish,
                          text: t,
                          method: "post",
                          customClass: "btn btn-primary bg-blue",
                        },
                      })
                    }
                  ),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "td-content" },
                [
                  _vm._l(race.red_players, function (player) {
                    return _c("span", { key: player.id }, [
                      _vm._v(_vm._s(player.name)),
                    ])
                  }),
                  _vm._v(" "),
                  _vm._l(
                    [
                      { u: "join", f: "canRedJoin", t: "加入" },
                      { u: "leave", f: "canRedLeave", t: "退出" },
                    ],
                    function ({ u, f, t }) {
                      return _c("ajax-button", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: race[f],
                            expression: "race[f]",
                          },
                        ],
                        key: f,
                        attrs: {
                          url: `${race.url}${u}`,
                          data: { side: "red" },
                          onFinish: _vm.onFinish,
                          text: t,
                          method: "post",
                          customClass: "btn btn-danger bg-red",
                        },
                      })
                    }
                  ),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "div",
                { staticClass: "td-content" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.stateText(race.state)))]),
                  _vm._v(" "),
                  _vm._l(
                    [
                      { u: "queue", f: "canQueue", t: "確認" },
                      { u: "bypass", f: "canByPass", t: "略過" },
                    ],
                    function ({ u, f, t }) {
                      return _c("ajax-button", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: race[f],
                            expression: "race[f]",
                          },
                        ],
                        key: f,
                        attrs: {
                          url: `${race.url}${u}`,
                          onFinish: _vm.onFinish,
                          text: t,
                          method: "post",
                          customClass: "btn btn-default",
                        },
                      })
                    }
                  ),
                  _vm._v(" "),
                  race.canScore
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-warning bg-orange",
                          attrs: { href: `/races/${race.id}/scorer` },
                        },
                        [_vm._v("\n            進入計分器\n          ")]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        }),
        _vm._v(" "),
        _c(
          "tr",
          _vm._l(
            [
              { c: "blue", t: "primary" },
              { c: "red", t: "danger" },
            ],
            function ({ c, t }) {
              return _c(
                "td",
                { key: c },
                [
                  _c("ajax-button", {
                    attrs: {
                      url: _vm.urlJoinNew,
                      data: { side: c },
                      onFinish: _vm.onFinish,
                      method: "post",
                      text: "加入",
                      customClass: `btn btn-${t} bg-${c} margin-top-2`,
                    },
                  }),
                ],
                1
              )
            }
          ),
          0
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "bg-gray-light" }, [
      _c("tr", [
        _c("th", [_vm._v("藍隊")]),
        _vm._v(" "),
        _c("th", [_vm._v("紅隊")]),
        _vm._v(" "),
        _c("th", [_vm._v("目前狀態")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }