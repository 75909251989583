<template>
  <div @click="change">
    <img alt="" :src="src" :class="`${imgClass}`"  />
    <input type="hidden" name="genders[]" :value="gender" />
  </div>
</template>

<script>
export default {
  props: ["defaultValue"],
  data() {
    return {
      value: undefined,
    }
  },
  methods: {
    change() {
      const { gender } = this;
      this.value = gender ? 0 : 1;
    }
  },
  computed: {
    gender() {
      const { value, defaultValue } = this;
      if (value !== undefined) return value;
      if (defaultValue !== undefined) return defaultValue;
      return 0;
    },

    src() {
      const filename = this.gender ? "female" : "male";
      return `/assets/images/icons/${filename}.png`;
    },

    imgClass() {
      return this.gender ? "bg-red" : "bg-blue";
    },
  }
};
</script>

<style scoped>
  div {
    cursor: pointer;
  }

  img {
    height: 30px;
    padding: 3px;
    border-radius: 5px;
  }
</style>
