var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "record" }, [
    _c("div", { staticClass: "record-mask" }, [
      _vm.isJudge && _vm.isKickboxing == false
        ? _c(
            "a",
            {
              staticClass: "btn btn-lg btn-warning bg-orange",
              attrs: { href: _vm.scorerUrl },
            },
            [_vm._v("進行中")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isJudge == false
        ? _c("div", { staticClass: "btn btn-lg btn-warning bg-orange" }, [
            _c("span", [_vm._v("進行中")]),
            _c("br"),
            _vm._v(" "),
            _c("span", [_vm._v("裁判：" + _vm._s(_vm.judgeName))]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "record-card" }, [
      _c(
        "div",
        { staticClass: "record-team" },
        [
          _vm._l(_vm.bluePlayers, function (item) {
            return _c(
              "div",
              { key: item.id, class: _vm.getPlayerClass(item, true) },
              [
                _c("img", { attrs: { src: item.avatar_url } }),
                _vm._v(" "),
                _c("div", { staticClass: "info full" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _vm.bluePlayers.length == 0
            ? _c("div", { class: _vm.getPlayerClass(0, true) }, [
                _c("img", { attrs: { src: _vm.unknownAvatarUrl } }),
                _vm._v(" "),
                _c("div", { staticClass: "info full" }, [_vm._v("???")]),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "record-score" }, [
        _c("div", { staticClass: "score" }, [
          _c("span", [_vm._v("?")]),
          _vm._v(" "),
          _c("div", { staticClass: "point hidden-xs" }, [
            _vm._v("( " + _vm._s(_vm.bluePoint) + " )"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(" - ")]),
        _vm._v(" "),
        _c("div", { staticClass: "score" }, [
          _c("span", [_vm._v("?")]),
          _vm._v(" "),
          _c("div", { staticClass: "point hidden-xs" }, [
            _vm._v("( " + _vm._s(_vm.redPoint) + " )"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "record-team" },
        [
          _vm._l(_vm.redPlayers, function (item) {
            return _c(
              "div",
              { key: item.id, class: _vm.getPlayerClass(item, false) },
              [
                _c("img", { attrs: { src: item.avatar_url } }),
                _vm._v(" "),
                _c("div", { staticClass: "info full" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _vm.redPlayers.length == 0
            ? _c("div", { class: _vm.getPlayerClass(0, false) }, [
                _c("img", { attrs: { src: _vm.unknownAvatarUrl } }),
                _vm._v(" "),
                _c("div", { staticClass: "info full" }, [_vm._v("???")]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }