var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("small", { class: `text--${_vm.state}` }, [
    _vm._v("\n  " + _vm._s(_vm.texts[_vm.state] || _vm.state) + "\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }