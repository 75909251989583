var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      { staticClass: "btn btn-default", on: { click: _vm.onMinus } },
      [_vm._v("-")]
    ),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.crowd))]),
    _vm._v(" "),
    _c("button", { staticClass: "btn btn-default", on: { click: _vm.onAdd } }, [
      _vm._v("+"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }