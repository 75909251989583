<template>
  <div class="range-container" :style="{ '--color': color }">
    <div class="line">
      <small :data-value="value" :style="{ left: `${percent * 100}%` }" />
      <b :style="{ transform: `scaleX(${percent})` }" />
      <span
        v-for="num in list"
        :key="num"
        :class="{
          active: num <= value,
          focus: num === value,
        }"
      />
    </div>
    <input
      type="range"
      step="1"
      :name="name"
      :min="min"
      :max="max"
      :value="value"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  props: ['name', 'min', 'max', 'color', 'value', 'onChange'],

  data() {
    return { currentValue: undefined };
  },

  methods: {
    onInput({ target }) {
      if (this.onChange) this.onChange({ target });
    }
  },

  computed: {
    percent() {
      const { value, min, max } = this;
      return (value - min) / (max - min);
    },
    list() {
      const { min, max } = this;
      return new Array(max - min + 1).fill(0).map((_, index) => min + index);
    },
  },

  watch: {
    value() {
      const { value, onChange } = this;
      if (!onChange) return;
      onChange({ target: { value } });
    },
  },
};
</script>

<style scoped lang="scss">
  $white: #fff;
  $gray: #e8e2d3;

  .range-container {
    position: relative;
    display: flex;
    margin: 10px 5px 20px;
    padding: 32px 9px 0;
    height: 52px;
    font-size: 14px;
  }

  .line {
    position: relative;
    display: flex;
    width: 100%;
    padding: 8px 0 0;
    justify-content: space-between;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 6px;
      background: $gray;
    }
  }

  span {
    width: 2px;
    height: 10px;
    background: $gray;
    transform-origin: top;
    transition: background 0.3s, transform 0.3s;

    &.active {
      background: var(--color);
    }

    &.focus {
      transform: scaleY(2);
    }
  }

  b {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 6px;
    background: var(--color);
    transform-origin: left;
  }

  small {
    position: absolute;
    bottom: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    transform: translate(-50%, -2px) scale(1.2);
    transform-origin: bottom;

    &::before {
      content: "";
      border: 10px solid transparent;
      border-top-color: var(--color);
      border-bottom: 0;
      transform: translate(0, -48%)
    }

    &::after {
      content: attr(data-value);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      color: $white;
      background: var(--color);
      border-radius: 50%;
    }
  }

  input[type="range"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &::-webkit-slider-thumb {
      background-clip: padding-box;
      border: 0.4rem solid rgba(0, 255, 0, 0.5);
      transform: scale(4);
    }
    &::-moz-range-thumb {
      background-clip: padding-box;
      border: 0.4rem solid rgba(0, 255, 0, 0.5);
      box-sizing: border-box;
      transform: scale(4);
    }
  }
</style>
