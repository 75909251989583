var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "range-container", style: { "--color": _vm.color } },
    [
      _c(
        "div",
        { staticClass: "line" },
        [
          _c("small", {
            style: { left: `${_vm.percent * 100}%` },
            attrs: { "data-value": _vm.value },
          }),
          _vm._v(" "),
          _c("b", { style: { transform: `scaleX(${_vm.percent})` } }),
          _vm._v(" "),
          _vm._l(_vm.list, function (num) {
            return _c("span", {
              key: num,
              class: {
                active: num <= _vm.value,
                focus: num === _vm.value,
              },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "range",
          step: "1",
          name: _vm.name,
          min: _vm.min,
          max: _vm.max,
        },
        domProps: { value: _vm.value },
        on: { input: _vm.onInput },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }