<template>
  <div class="btn-group flex-center">
    <ajax-button
      v-for="{ type, text, icon, customClass, data } in genders"
      :key="type"
      :text="text"
      :url="url"
      :icon="icon"
      :params="data"
      :customClass="customClass"
      :onFinish="onFinish"
    ></ajax-button>
  </div>
</template>

<script>
import AjaxButton from './AjaxButton';

const genders = [
  { type: 0, text: '男', customClass: 'btn-primary bg-blue' },
  { type: 1, text: '女', customClass: 'btn-danger bg-red' },
];

export default {
  props: ['value', 'url', 'data'],
  components: { AjaxButton },

  data() {
    return {
      gender: undefined,
    };
  },

  computed: {
    currentGender() {
      const { gender, value } = this;
      if (gender !== undefined) return gender;
      if (value.gender == 'male') return 0;
      if (value.gender == 'female') return 1;
      return undefined;
    },

    genders() {
      const { data, currentGender } = this;

      return genders.map((g) => {
        const checked = g.type === currentGender;
        return {
          ...g,
          data: { ...data, gender: g.type },
          icon: checked ? 'ok' : undefined,
          customClass: `btn ${checked ? g.customClass : 'btn-link text-white'}`,
        };
      });
    },
  },

  methods: {
    onFinish(res, params) {
      if (!res || !res.statusText || res.statusText !== 'OK') return;
      this.gender = params.gender;
    },
  },
};
</script>
