var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-group" },
    _vm._l(_vm.events, function (event) {
      return _c(
        "button",
        {
          key: event,
          staticClass: "btn btn-default",
          attrs: { type: "button", "data-type": event },
          on: {
            click: function ($event) {
              return _vm.onClick(event)
            },
          },
        },
        [
          _c("span", {
            staticClass: "glyphicon",
            class: `glyphicon-${_vm.icons[event]}`,
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v("\n    " + _vm._s(_vm.texts[event] || event) + "\n  "),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }